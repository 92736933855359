import { AnyAction, combineReducers } from 'redux';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/nextjs";
import { slices } from './slices';
import { notificationPush } from "./slices/notification.slice";
import { createRouterMiddleware, initialRouterState, routerReducer } from 'connected-next-router';
import Router from 'next/router';
import { getConfig } from '../../../config/config';

const routerMiddleware = createRouterMiddleware();

Sentry.init({
  dsn: getConfig().sentry.dsn,
});

Sentry.setTag('app', 'redux');
Sentry.setTag('region', getConfig().theme);

//Actions, на которые не должно быть уведомлений
const actionsNotificationsBlackList = [
  'auth/changePassword/rejected',
  'auth/changeEmail/rejected'
]

const apiErrorHandler = ({ dispatch }) => next => action => {
  if (!actionsNotificationsBlackList.includes(action.type) && action.type.match(/\/rejected/)) {
    // eslint-disable-next-line no-console
    console.error(action);
    dispatch(notificationPush({
      content: action?.payload?.response?.data?.userMessage || 'Внутренняя ошибка сервера',
      type: 'error'
    }));
  }
  return next(action);
};

const captureSentryErrorMiddleware = () => {
  return (next: any) => (action: AnyAction) => {
    if (action.type.match(/\/rejected/)) {
      let error: Error;
      if (action.error.stack) {
        error = new Error(action.error.message);
        error.name = action.error.name;
        error.stack = action.error.stack;
      } else {
        error = new Error(action.payload)
      }

      if (!action.error?.message?.match(/status code 4\d\d$/)) {
        Sentry.addBreadcrumb({
          category: 'redux-action',
          message: action.type,
          level: 'info'
        });

        Sentry.captureException(error);
      }
    }

    return next(action);
  }
}

const initStore = (context) => {
  const reducers = {};
  slices.forEach(slice => {
    reducers[slice.name] = slice.reducer;
  });
  const combined = combineReducers({
    ...reducers,
    router: routerReducer,
    errorHandler: (state, { type, error, ...data }) => {
      if (type.match(/\/rejected/)) {
        if (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        } else {
          // eslint-disable-next-line no-console
          console.warn(type, data);
        }
      }
      return null;
    }
  });
  const reducer = (state: any = {}, action) => {
    if (action.type === HYDRATE) {
      const nextState = {
        ...state, // use previous state
        ...action.payload, // apply delta from hydration
      };

      // preserve count value on client side navigation
      if (state.count && state.count.count) nextState.count.count = state.count.count;

      // Enable connected router
      if (typeof window !== 'undefined' && state?.router) {
        // preserve router value on client side navigation
        nextState.router = state.router
      }
      return nextState;
    } else {
      return combined(state, action);
    }
  };

  // Apply connected router middleware
  const { asPath } = context.ctx || Router.router || {}
  let initialState
  if (asPath) {
    initialState = {
      router: initialRouterState(asPath)
    }
  }
  return configureStore({
    reducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false, immutableCheck: { warnAfter: 200 } })
        .concat([apiErrorHandler, routerMiddleware, captureSentryErrorMiddleware]),
    devTools: true,
  });
};

export const reduxWrapper = createWrapper(initStore);
