import { isEmpty, omit, uniq } from "lodash";
import { getCookie } from 'cookies-next';
import { IncomingMessage, ServerResponse } from "http";
import { ParsedUrlQuery } from "querystring";
import { paramsSerializer } from "../urls";
import { Store } from "redux";
import { loadLocales } from "../redux/slices/locales.slice";
import { ILocale } from "../redux/types/common";
import {
  Filter, FilterTypes, Interim, QueryFilters, filtersGet, filtersHas, getQueryFilter, getStartEndByInterim
} from "../query/queryFilter";
import { getDateByWordForm } from "./dateWordForms";
import { fetchPlaces } from "../../kursk/utils/fetchPlaces";

export const LOCALES_COOKIE_NAME = 'cultreg_locales';

// TODO доделать функцию переноса нового фильтра в query
const addFilterToQuery = async (
  query: ParsedUrlQuery, filters: QueryFilters, allLocales: ILocale[]
): Promise<ParsedUrlQuery> => {
  if (!filters || !filters.length) {
    return query;
  }
  const newQuery = omit(query, 'filter');

  if (filtersHas(FilterTypes.DATE, filters)) {
    newQuery.start = String(
      getDateByWordForm(filtersGet(FilterTypes.DATE, filters)).start
    );
  }

  if (filtersHas(FilterTypes.INTERIM, filters)) {
    const { start, end } = getStartEndByInterim(filtersGet(FilterTypes.INTERIM, filters) as Interim) || {};

    if (start) {
      newQuery.start = String(start);
    }

    if (end) {
      newQuery.end = String(end);
    }
  }

  if (filtersHas(FilterTypes.TAG, filters)) {
    const tag = filtersGet(FilterTypes.TAG, filters);
    newQuery.tags = newQuery.tags ? newQuery.tags + `,${tag}` : `${tag}`;
  }

  if (filtersHas(FilterTypes.GENRE, filters)) {
    const genre = filtersGet(FilterTypes.GENRE, filters);
    newQuery.genres = newQuery.genres ? newQuery.genres + `,${genre}` : `${genre}`;
  }

  if (filtersHas(FilterTypes.PLACE, filters)) {
    const [place] = await fetchPlaces([filtersGet(FilterTypes.PLACE, filters)]);
    newQuery.places = newQuery.places ? newQuery.places + `,${place}` : `${place}`;
  }

  if (filtersHas(FilterTypes.LOCALE, filters)) {
    const locale = filtersGet(FilterTypes.LOCALE, filters);
    const fullLocale = allLocales.find(({ sysName }) => sysName === locale);
    newQuery.locales = newQuery.locales ? newQuery.locales + `,${fullLocale?._id}` : `${fullLocale?._id}`;
  }

  return newQuery;
}

const getNewURL = (query: ParsedUrlQuery, cookieLocalesIds: number[], pathname: string) => {
  const queryLocalesIds = (query.locales as string)?.split(',').map(Number).filter((n) => !isNaN(n)) || [];
  const newLocales = uniq([...cookieLocalesIds, ...queryLocalesIds].filter(Boolean));
  if (newLocales.length === queryLocalesIds.length) {
    return null;
  }

  const params = paramsSerializer({
    ...omit(query, 'locales'),
    locales: newLocales
  });

  return `${pathname}?${params}`;
}

const getLocaleFilter = (localeId: number, allLocales: ILocale[]) =>
  `/district-${allLocales.find(({ _id }) => _id === localeId)?.sysName}`;

const getOtherFilter = (newFilter: Filter) => 
  `${newFilter.type}-${newFilter[newFilter.type]}`;

const getDestinationUrl = async (
  url: string, query: ParsedUrlQuery, cookieLocales: string, allLocales: ILocale[]
): Promise<string> => {
  let pathname = url.split('?').shift();
  const cookieLocalesIds = cookieLocales.split(',').map(Number).filter((n) => !isNaN(n));
  const newFilters = getQueryFilter(query);

  // либо в query, либо вообще ничего нет
  if (!newFilters) {
    // если query пусто и в куки только одна локаль, то добавляем ее как новый фильтр
    if (isEmpty(query) && cookieLocalesIds.length === 1) {
      pathname = pathname + getLocaleFilter(cookieLocalesIds[0], allLocales);
      return pathname;
    } else {
      // если что-то есть в query или локалей в куки больше одной, то формируем новую query
      return getNewURL(query, cookieLocalesIds, pathname);
    }
  } else {
    // если в фильтрах уже есть локали или уже два других фильтра или в куки больше одной локали, то формируем query
    if (filtersHas(FilterTypes.LOCALE, newFilters) || newFilters.length === 2 || cookieLocalesIds.length > 1) {
      const queryWithFilters = await addFilterToQuery(query, newFilters, allLocales);
      return getNewURL(queryWithFilters, cookieLocalesIds, pathname);
    } else {
      // если в фильтрах нет локалей и только один фильтр и в куки только одна локаль, то формируем ЧПУ
      if (
        filtersHas(FilterTypes.TAG, newFilters) ||
        filtersHas(FilterTypes.GENRE, newFilters) ||
        filtersHas(FilterTypes.INTERIM, newFilters)
      ) {
        pathname = pathname + '/' +
          getOtherFilter(newFilters[0]) +
          getLocaleFilter(cookieLocalesIds[0], allLocales);
      } else {
        pathname = pathname +
          getLocaleFilter(cookieLocalesIds[0], allLocales) +
          getOtherFilter(newFilters[0]);
      }

      return pathname;
    }
  }  
}

/*
* Функция для проверки выбранного района в фильтре
* нужно применять в getServerSideProps для страниц где используется фильтр по району
*/
export const checkLocales = async (
  resolvedUrl: string, query: ParsedUrlQuery, req: IncomingMessage, res: ServerResponse, store: Store
) => {
  const cookieLocales = getCookie(LOCALES_COOKIE_NAME, { req, res }) as string;
  
  if (!cookieLocales || !cookieLocales.split(',').length) {
    return;
  }

  const allLocales = await store.dispatch(loadLocales({}) as any);
  const destination = await getDestinationUrl(resolvedUrl, query, cookieLocales, allLocales?.payload || []);
  

  if (destination) {
    return {
      redirect: {
        destination,
        permanent: false,
      }
    };
  }
}
