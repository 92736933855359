import getNextConfig from 'next/config';
import { themeValue } from "../regions/kursk/themes";

export function envVal({ production = undefined, staging = undefined, development = undefined }, defaultValue = null) {
  const env = getNextConfig()?.publicRuntimeConfig?.NEXT_PUBLIC_SERVER_ENV || process.env.NEXT_PUBLIC_SERVER_ENV;
  if (env === 'production' && production !== undefined) {
    return production;
  }
  if (env === 'staging' && staging !== undefined) {
    return staging;
  }
  if ((env === 'development' || process.env.NEXT_PUBLIC_SERVER_ENV === 'test') && development !== undefined) {
    return development;
  }
  return defaultValue;
}


export const getConfig = (isPublic = true) => {
  const env = getNextConfig()?.publicRuntimeConfig || process.env;
  const result = {
    region: env.NEXT_PUBLIC_REGION_NAME,
    theme: env.NEXT_PUBLIC_REGION_THEME,
    env: env.NEXT_PUBLIC_SERVER_ENV || 'development',
    nodeEnv: isPublic ? undefined : process.env.NODE_ENV,
    portal: {
      host: isPublic ? undefined : process.env.PORTAL_HOST,
      port: isPublic ? undefined : Number(process.env.PORTAL_PORT) || undefined,
      url: env.NEXT_PUBLIC_PORTAL_URL,
    },
    api: {
      url: env.NEXT_PUBLIC_API_URL,
      innerUrl: isPublic ? undefined : env.API_INNER_URL,
    },
    liveRedirect: {
      enabled: false,
      url: isPublic ? undefined : process.env.LIVE_INNER_URL,
    },
    backend: {
      url: env.BACKEND_URL || env.NEXT_PUBLIC_API_URL,
      innerUrl: isPublic ? undefined : (env.BACKEND_INNER_URL || env.BACKEND_URL),
    },
    imageFilter: {
      innerUrl: isPublic ? undefined : process.env.IMAGE_FILTER_INNER_URL || process.env.NEXT_PUBLIC_API_URL,
    },
    oldPortal: {
      innerUrl: isPublic ? undefined : process.env.OLD_PLATFORM_INNER_URL,
      districts: false,
      map: false,
    },
    eventSchedulesActiveDays: themeValue({ pprog: false, default: true }),
    subscribeDisabled: themeValue({ sirius: true, lnr: true, default: false }),
    subscribeModalDisabled: {
      desktop: themeValue({ lnr: true, default: false }),
      tablet: themeValue({ lnr: true, default: false }),
      mobile: themeValue({ primorsky: true, lnr: true, default: false })
    },
    enkod: envVal({
      staging: themeValue({
        bel: 'culreg', // Нет, это не опечатка
        default: null
      }),
      production: null,
      development: null
    }),
    testgraph: null, // Отклчено до улучшения фунекционала тестографа
    disableRobots: envVal({ production: false }, true),
    auth: {
      vk: true,
      phone: themeValue({ pprog: false, default: true }),
      esia: themeValue({
        bel: envVal({ staging: false, development: false }, false),
        default: false,
      }),
    },
    seo: {
      ldJson: {
        enabled: true,
      },
      openGraph: {
        enabled: true,
      }
    },
    sentry: {
      dsn: envVal({
        staging: 'https://08d6aeba02424df5bae82035b2c99fd6@sentry.fabit.ru/8',
        production: 'https://f86a3a9c1ceb4139b314c34fceafa037@sentry.fabit.ru/9',
        development: ''
      })
    },
    widgetTheme: themeValue({
      bel: 'belNew',
      kursk: 'kursk',
      sirius: 'sirius',
      primorsky: 'primorsky',
      sakhalin: 'sakhalin',
      vladimir: 'vladimir',
      pprog: 'pprog',
      sochi: 'sochi',
      lnr: 'lnr',
      astrakhan: 'astrakhan',
      default: 'default',
    }),
    yandexCaptchaKey: themeValue({
      bel: 'ysc1_Ox9P3sS7i3NdndLY3vPWhdYabp34oSMWepRSSRSR86c708b3',
      kursk: 'ysc1_RgiWyuVFhaYB3AtWcb4gHR1kPVPVvNpyRxZkOXhSaa197080',
      primorsky: 'ysc1_aTLnZpz9WVmkmU35TBXq3w1zJ3Sd5EwizBsrGyG6faf71dc2',
      sakhalin: 'ysc1_SmDYUZ1JRlEK6SXlG2gLbpQcsTZwPbSv9cV6etdn84303ee0',
      vladimir: 'ysc1_5m4bRcNctfkprR54zkE7itfzsFqJw3BSYWj9lFFg99c87337',
      sochi: 'ysc1_sWvigtvpyr5prc2AEEsUOkk1kd68BmFvftGNekmK432c9fbd',
      lnr: 'ysc1_Skdj3Vgj6bhSIy5ckmdnIN80sMaQlUp53G0Tb6pSf67d5189',
      default: null,
    }),
  };
  return result;
};
