import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from "../../urls";
import { IMovie } from "../types/movie";

interface IState {
  movie?: IMovie;
}

export const loadMovieThunk = createAsyncThunk<any, any>('movie/loadMovieThunk',
  async (params: any) => {
    const response = await axios.get(api.movies.getOne(params.id));
    return {
      movie: response.data.movie || null,
    };
  }
);

export const movieSlice = createSlice<IState, any>({
  name: 'moviesList',
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadMovieThunk.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});
