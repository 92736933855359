import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { IArticle } from "../types/articles";

interface IState {
  articles: IArticle[];
  article?: IArticle;
}

export const loadArticle = createAsyncThunk<any, any>('articleList/loadArticle',
  async (params: { _id: number }) => {
    const response = await axios.get(api.articles.getOne(params._id));
    return {
      article: response.data.article || null,
    };
  }
);

export const articlesSlice = createSlice<IState, any>({
  name: 'articleList',
  initialState: {
    articles: []
  },
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadArticle.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});

export const { setActiveMobileTab } = articlesSlice.actions;
