import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../urls";
import { IEvent } from "../types/event";
import { IHotel, IPlace, IRestaurant } from "../types/places";
import { IArticle, IRoute } from "../types/articles";
import { IStream } from "../types/stream";
import { EntityType } from "../types/common";
import { SliceAction } from "../types/sliceAction";
import { IHint } from "../../../kursk/components/pages/Search/Search";
import { IMovie } from "../types/movie";

export type EntitySearchTypes = 'events' | 'places' | 'restaurants' | 'streams' | 'regionvideos' |
'hotels' | 'obzor' | 'marshrut' | 'artefact' | 'virtualMuseum' | 'virtualHall' | 'hints' | 'movies';

type SearchExtraParams = {
  [p in EntitySearchTypes]: { entityType: EntityType | string, [p: string]: unknown };
}

export interface ISearchEntity<T> {
  items: T;
  total: number;
  offset?: number;
}

interface IState {
  artefact: ISearchEntity<IArticle[]>;
  virtualMuseum: ISearchEntity<IPlace[]>;
  virtualHall: ISearchEntity<IPlace[]>;
  events: ISearchEntity<IEvent[]>;
  places: ISearchEntity<IPlace[]>;
  restaurants: ISearchEntity<IRestaurant[]>;
  hotels: ISearchEntity<IHotel[]>;
  obzor: ISearchEntity<IArticle[]>;
  marshrut: ISearchEntity<IRoute[]>;
  streams: ISearchEntity<IStream[]>;
  regionvideos: ISearchEntity<IArticle[]>;
  movies: ISearchEntity<IMovie[]>;
  hints: ISearchEntity<IHint[]>;
}

export const loadSearchResults = createAsyncThunk<any, any>('search/loadEvents',
  async ({ entityType, ...params }, { getState }) => {
    try {
      const extraParams: SearchExtraParams = {
        events: {
          entityType: 'events',
        },
        artefact: {
          url: api.articles.get(),
          entityType: undefined,
          isArtefact: 1
        },
        virtualHall: {
          entityType: 'virtualHalls',
        },
        places: {
          entityType: 'places',
        },
        hotels: {
          entityType: 'hotels',
        },
        restaurants: {
          entityType: 'restaurants',
        },
        marshrut: {
          entityType: 'touristRoutes',
        },
        obzor: {
          entityType: 'articles',
        },
        streams: {
          entityType: 'streams',
        },
        regionvideos: {
          url: api.overviews.get(),
          entityType: undefined,
          hasVideo: 1,
          fromPickOnly: 'regionVideos',
        },
        virtualMuseum: {
          entityType: 'virtualMuseums'
        },
        movies: {
          entityType: 'movies'
        },
        hints: {
          entityType: 'hints',
          url: api.hints.get()
        }
      }
      const state = getState()['search'];
      const { url, ...otherExtraParams } = extraParams[entityType];
      let newParams;
      if (entityType === "hints") {
        newParams = {
          ...params,
        };
      }

      if (entityType !== "hints") {
        newParams = {
          limit: 5,
          offset: state[entityType].offset,
          ...otherExtraParams,
          ...params,
        };
      }

      const response = await axios.get(url || api.search.get(), { params: newParams });
      const key = Object.keys(response.data).filter(key => key !== 'total').pop();
      let res;
      if (entityType === "hints") {
        res = {
          entityType,
          items: response.data,
        };
      }

      if (entityType !== "hints") {
        res = {
          entityType,
          items: response.data[key],
          total: response.data.total,
        };
      }
      return res;
    } catch (e) {
      const res = {
        entityType,
        items: [],
        total: 0,
      };
      return res;
    }
  }
);

export const searchSlice = createSlice<IState, any>({
  name: 'search',
  initialState: {
    virtualHall: {
      items: [],
      total: 0,
      offset: 0,
    },
    streams: {
      items: [],
      total: 0,
      offset: 0,
    },
    regionvideos: {
      items: [],
      total: 0,
      offset: 0,
    },
    virtualMuseum: {
      items: [],
      total: 0,
      offset: 0,
    },
    artefact: {
      items: [],
      total: 0,
      offset: 0,
    },
    events: {
      items: [],
      total: 0,
      offset: 0,
    },
    places: {
      items: [],
      total: 0,
      offset: 0,
    },
    restaurants: {
      items: [],
      total: 0,
      offset: 0,
    },
    hotels: {
      items: [],
      total: 0,
      offset: 0,
    },
    obzor: {
      items: [],
      total: 0,
      offset: 0,
    },
    marshrut: {
      items: [],
      total: 0,
      offset: 0,
    },
    movies: {
      items: [],
      total: 0,
      offset: 0,
    },
    hints: {
      items: [],
      total: 0,
    }
  },
  reducers: {
    setOffset(state: IState, { payload }: PayloadAction<any>) {
      state[payload.entityType].offset = payload.nextOffset;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadSearchResults.fulfilled, (state, { payload }) => {
      if (payload && payload.entityType) {
        const type = payload.entityType;
        if (state[type].offset === 0) {
          state[type].items = payload.items;
          state[type].total = payload.total;
        } else if (payload.entityType !== "hints") {
          state[type].items = [...state[type].items, ...payload.items];
          state[type].total = payload.total;
        } else if (payload.entityType === "hints") {
          state[type].items = payload.items;
        }
      }
    });
  }
});

export const setSearchOffset = searchSlice.actions.setOffset as SliceAction;
