/* eslint-disable max-len */
import { envVal } from '@root/config/config';
import moment from 'moment';
import { getStreamPlaceOrEvent } from "@kursk/content/contentUtils";
import { capitalize } from 'lodash';

const year = new Date().getFullYear();

const tagPageDefaultSeo = {
  title: ({ tagName }) => `${tagName[0].toUpperCase() + tagName.slice(1)} в Сочи ${year}. Официальный сайт — Visit Sochi`,
  description: ({ tagName }) => `${tagName[0].toUpperCase() + tagName.slice(1)} в городе Сочи. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты`,
  titleOg: () => '',
  h1: ({ tagName }) => tagName
};
const tagPageDefaultSeoEvents = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Сочи ${year}. Официальный сайт — Visit Sochi`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в городе Сочи. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты`,
  titleOg: () => '',
};
const tagPageDefaultSeoPlaces = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} Сочи. Официальный сайт — Visit Sochi`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в городе Сочи. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание`,
  titleOg: () => '',
};

export const sochiContent = {
  timezone: 'Europe/Moscow',
  yandexMapState: {
    center: [43.614824, 39.728550],
    zoom: 9,
  },
  locale: { _id: 1469 },
  title: 'Visit Sochi',
  subTitle: 'Туристический портал г. Сочи',
  mainBanner: {
    hide: true,
    title: '',
    text: '',
    links: []
  },
  useBvi: false,
  phone: '8 800 350-06-02',
  mail: 'support@visit-sochi.com',
  agreementLink: '/public_offer',
  socials: {
    ok: '',
    vk: 'https://vk.com/visitsochi.official',
    yaDzen: '',
    telegram: 'https://t.me/visit_sochi',
    facebook: '',
    instagram: '',
    youtube: 'https://www.youtube.com/channel/UChgKZtRV4gXpvbUwQp9cbbw',
  },
  socialTitles: {
    vk: 'вконтакте',
    telegram: 'Telegram',
  },
  subscribeForm: {
    title: 'Будьте в курсе всех новостей',
  },
  maintenanceBanner: {
    enabled: true,
    text: 'В связи с техническими работами возможны сбои в работе портала',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Пушкинская карта', to: '/events/tag-pushkinskaya-karta' },
          { title: 'Концерты и шоу', to: '/events/tag-koncerty-i-shou' },
          { title: 'Спектакли', to: '/events/tag-spektakli' },
          { title: 'Выставки', to: '/events/tag-vystavki' },
          { title: 'Мастер-классы', to: '/events/tag-master-klassy' },
          { title: 'Кино', to: '/movies' },
          { title: 'Фестивали', to: '/events/tag-festivali' },
          { title: 'Праздники', to: '/events/tag-prazdniki' }
        ]
      },
      {
        title: 'Места',
        relativeLink: '/places',
        links: [
          { title: 'Все места', to: '/places' },
          { title: 'Кафе и рестораны', to: '/restaurants' },
        ]
      },
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Live', to: '/streams' },
      { title: 'Гостиницы', to: '/hotels' },
    ],
  },
  footer: {
    title: 'Visit Sochi',
    systemName: `© ${year}. Visit Sochi. Туристический портал г. Сочи. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      { title: 'Афиша', to: '/events' },
      { title: 'Абонементы', to: '/ticketsbundles' },
      { title: 'Места', to: '/places' },
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Гостиницы', to: '/hotels' },
      { title: 'Рестораны', to: '/restaurants' },
    ],
    callTime: 'В будние дни с 9:00 до 18:00'
  },
  analytics: {
    yandex: envVal({ production: '97192864' }, null),
    gtm: envVal({ production: '' }, null),
    pixel: envVal({ production: '' }, null),
    goals: {
      paidVisit: 'events_visit_sochi',
      paidBuy: 'buy_ticket_sochi',
      freeVisit: 'events_visits_sochi',
      freeNoTicketsVisit: 'noactive_free_sochi',
      freeBuy: 'freeandzero_sochi',
      externalLinkVisit: 'events_visit_redirect_sochi',
      externalLinkBuy: 'buy_redirect_sochi',
      ticketsAdd: 'entity_ticket_add',
    }
  },
  unisenderNewsPopup: {
    id: envVal({ staging: '', }, null),
  },
  widgetWeather: {
    city: 'sochi',
    weatherServiceLabel: 'gismeteo.ru',
    weatherServiceLink: 'https://www.gismeteo.ru/weather-sochi-5233/3-days/',
  },
  componentsConfig: {
    header: 'default',
    footer: 'default',
    heroBlock: 'banner',
    socialCardIconVk: 'sochi',
    socialCardIconTg: 'sochi',
    pages: {
      index: {
        header: 'transparent',
        heroBlock: 'video',
      },
    },
  },
  seo: {
    pages: {
      index: {
        title: () => 'Туристический портал города Сочи — Официальный сайт Visit Sochi. visit-sochi.com',
        description: () => 'Туристический портал города Сочи: события, места, маршруты, обзоры, гостиницы и рестораны',
        titleOg: () => 'Туристический портал города Сочи — Официальный сайт Visit Sochi.'
      },
      publicAgreement: {
        title: () => 'Политика конфиденциальности и публичная оферта',
        description: () => 'Политика конфиденциальности и публичная оферта',
      },
      events: {
        title: () => `Куда сходить в Сочи ${year}. Афиша ближайших мероприятий Сочи. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
        description: () => 'Куда можно пойти в городе Сочи. Афиша интересных мероприятий в городе Сочи. Подробная информация о событиях: описания, места, расписания, ☎ телефоны',
        titleOg: () => '',
      },
      'tagPageDefaultSeoevents': tagPageDefaultSeoEvents,
      places: {
        title: () => `Все интересные места и достопримечательности города Сочи ${year}. Официальный сайт — Visit Sochi`,
        description: () => `Интересные и необычные достопримечательности города Сочи ${year} — описания, цены на билеты, адреса, режим работы, фотографии, адреса на карте`,
        titleOg: () => ''
      },
      'tagPageDefaultSeoplaces': tagPageDefaultSeoPlaces,
      'tagPageDefaultSeo': tagPageDefaultSeo,
      routes: {
        title: () => `Туристические маршруты в городе Сочи ${year}. Официальный сайт — Visit Sochi`,
        description: () => `Лучшие туристические маршруты города Сочи, которые можно пройти пешком или проехать на автомобиле. Карты туристических маршрутов, описание, необычные идеи для путешествия по городу Сочи`,
        titleOg: () => ''
      },
      overviews: {
        title: () => `Обзоры ${year} — город Сочи. Официальный сайт — Visit Sochi`,
        description: () => `Обзоры лучших событий, мест ${year} — Сочи. Официальный сайт — Visit Sochi`,
        titleOg: () => ''
      },
      regionvideos: {
        title: () => `Видео о городе Сочи, ${year}. Официальный сайт — Visit Sochi`,
        description: () => `Видео о городе Сочи, ${year}. Официальный сайт — Visit Sochi`,
        titleOg: () => '',
      },
      hotels: {
        title: () => `Гостиницы в городе Сочи ${year}. Официальный сайт — Visit Sochi`,
        description: () => 'Гостиницы города Сочи. Подробная информация: описания, адрес, режимы работы, фото, адреса на карте, рейтинги',
        titleOg: () => ''
      },
      restaurants: {
        title: () => `Кафе и рестораны в Visit Sochi ${year}. Официальный сайт — Visit Sochi`,
        description: () => 'Рестораны и кафе города Сочи. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги',
        titleOg: () => ''
      },
      streams: {
        title: () => 'Трансляции на сайте. Официальный сайт — Visit Sochi',
        description: () => 'Все трансляции и площадки их проведения, события. Видео о регионе. Официальный сайт — Visit Sochi',
        titleOg: () => '',
        h1: () => `Трансляции - Сочи ${year}`,
      },
      ticketsBundles: {
        title: () => 'Абонементы. Официальный сайт — Visit Sochi',
        description: () => 'Абонементы в городе Сочи. Официальный сайт — Visit Sochi',
        titleOg: () => ''
      },
      movies: {
        title: () => `Киноафиша в Сочи | Афиша мероприятий с точными датами на ${year} год. Официальный сайт — Visit Sochi`,
        description: () => `Киноафиша в городе Сочи на ${year} год ✅ Афиша интересных мероприятий в городе Сочи. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Официальный сайт — Visit Sochi`,
        titleOg: () => ''
      },
      singleEvent: {
        title: ({ entity }) => `${entity.name} — описание, программа мероприятия, дата, время.
         Адрес места проведения — ${entity.address}. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `${entity.name}. ✅ Место проведения — ${entity.placeName},
         ✅ дата проведения — ${entity.eventDate ? moment(entity.eventDate).format('D MMMM YYYY') : ''}, ✅ адрес места проведения — ${entity.address}.
          Подробная информация: описание, фото`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singlePlace: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}.
        Фото, режим работы, афиша и события. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация: описание,
         адрес на карте, время работы, телефон, фотографии.
          Все туристические места и достопримечательности на сайте — Visit Sochi`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRoute: {
        title: ({ entity }) => `${entity.name}. Подробная информация: описание, адреса мест на карте, фото`,
        description: ({ entity }) => `${entity.name}. Лучшие туристические маршруты,
         которые можно пройти пешком или проехать на автомобиле. Официальный сайт — Visit Sochi`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleOverview: {
        title: ({ entity }) => `${entity.name}. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `Обзор - ${entity.name}. Официальный сайт Visit Sochi`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRegionVideo: {
        title: ({ entity }) => `Видео о городе Сочи, ${year}. ${entity.name}. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `Видео о городе Сочи, ${year}. ${entity.name}. Официальный сайт — Visit Sochi`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleHotel: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}.
         Фото, режим работы. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация:
         описание, адрес на карте, время работы, телефон, фотографии. Все гостиницы на сайте — Visit Sochi`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRestaurant: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}.
         Фото, режим работы. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация:
         описание, адрес на карте, время работы, телефон, фотографии. Все рестораны на сайте — Visit Sochi`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleStream: {
        title: ({ entity }) => `Трансляция ${entity.name}. ${getStreamPlaceOrEvent(entity)}Прямая трансляция на официальном сайте — Visit Sochi`,
        description: ({ entity }) => `${entity.name}. ${getStreamPlaceOrEvent(entity)}Дата и время проведения — ${entity.liveDate ? moment(entity.liveDate).format('D MMMM YYYY H:mm') : ''} на официальном сайте Visit Sochi`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleTicketsBundle: {
        title: ({ entity }) => `Абонемент ${entity?.name}. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `Абонемент ${entity?.name}. Официальный сайт — Visit Sochi`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleMovie: {
        title: ({ entity }) => `Фильм ${entity?.name} в Сочи  — афиша, цена на билеты,
         расписание, описание, место проведения. Официальный сайт — Visit Sochi`,
        description: () => `Киноафиша города Сочи с точным расписанием киносеансов во всех
         кинотеатрах города. Узнать цену и купить билет можно на странице с выбором фильма.
          На сайте можно узнать точное время и дату на сегодня, завтра или в другой день`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      pushkincard: {
        title: () => `Пушкинская карта — Visit Sochi`,
        description: () => 'Пушкинская карта — Visit Sochi',
        titleOg: () => `Пушкинская карта — Visit Sochi`,
      },
      main: { title: 'Туристический портал города Сочи — Visit Sochi.' },
      museums: {
        title: () => 'Виртуальные музеи в Сочи. Официальный сайт — Visit Sochi',
        description: () => 'Виртуальные музеи города Сочи. Официальный сайт — Visit Sochi'
      },
      singleMuseum: {
        title: ({ entity }) => `Виртуальные музеи. ${entity.name} в Сочи. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `Сочинские виртуальные музеи. ${entity.name}. Официальный сайт — Visit Sochi`
      },
      halls: {
        title: () => `Виртуальные концертные залы, ${year} — Сочи. Официальный сайт — Visit Sochi`,
        description: () => `Виртуальные концертные залы, ${year}. Сочи. Официальный сайт — Visit Sochi`
      },
      singleHall: {
        title: ({ entity }) => `Виртуальные концертные залы, ${year}, ${entity.name}. Сочи. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `Виртуальные концертные залы, ${year}, ${entity.name}. Сочи. Официальный сайт — Visit Sochi`
      },
      artefacts: {
        title: () => 'Дополненная реальность. Официальный сайт — Visit Sochi',
        description: () => 'Дополненная реальность. Погружение в виртуальный мир. Официальный сайт — Visit Sochi'
      },
      singleArtefact: {
        title: ({ entity }) => `${entity?.name}. Официальный сайт — Visit Sochi`,
        description: ({ entity }) => `${entity?.name}. Официальный сайт — Visit Sochi`,
      },
      search: {
        title: ({ searchQuery }) => `Поиск событий, мест, маршрутов, обзоров,
        ресторанов, гостиниц, абонементов по запросу «${searchQuery}»`,
        description: ({ searchQuery }) => `События, места, маршруты, обзоры,
        рестораны, гостиницы, абонементы по запросу «${searchQuery}»`,
      },
      faq: {
        title: () => `Часто задаваемые вопросы. Официальный сайт — Visit Sochi`,
        description: () => 'Часто задаваемые вопросы. Официальный сайт — Visit Sochi',
      },
      // Порядок вложенности необходимо соблюдать, иначе функция получения сео метаданных отработает неправильно
      // В единсвенном числе - фильтр (locale, place, date, ...), с приставкой Page(eventsPage, hotelsPage, ...) - страница, для которой метаданные
      // Примеры:
      // locale->date->eventsPage: Фильтр район + фильтр дата для страницы событий
      // place->tag->eventsPage->default: Фильтр место + тэг для страницы событий
      // tag->eventsPage->pushkinskaya-karta: Тэг Пушкцинская карта для страницы событий
      filters: {
        locale: {
          eventsPage: {
            title: ({ localeName }) => `${localeName} — афиша ${year}. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
            description: ({ localeName }) => `${localeName} — афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
            h1: ({ localeName }) => `${localeName} - афиша`,
          },
          placesPage: {
            title: ({ localeName }) => `Интересные места — ${localeName} ${year}. Официальный сайт — Visit Sochi`,
            description: ({ localeName }) => `Все интересные места и достопримечательности — ${localeName} ${year} — описания, цены на билеты, режим работы, фотографии, адреса на карте`,
            h1: ({ localeName }) => `Места - ${localeName}`,
          },
          restaurantsPage: {
            title: ({ localeName }) => `Кафе и рестораны — ${localeName} ${year}. Официальный сайт — Visit Sochi`,
            description: ({ localeName }) => `Кафе и рестораны — ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги`,
            h1: ({ localeName }) => `Кафе и рестораны - ${localeName}`
          },
          hotelsPage: {
            title: ({ localeName }) => `Гостиницы и отели — ${localeName} ${year}. Официальный сайт — Visit Sochi`,
            description: ({ localeName }) => `Гостиницы и отели — ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д.`,
            h1: ({ localeName }) => `Гостиницы и отели - ${localeName}`
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(localeName)} — ${tagName}. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты`,
                h1: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}`,
              }
            },
            placesPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName} ${year}. Интересные места. Официальный сайт — Visit Sochi`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            hotelsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName} ${year}. Интересные места. Официальный сайт — Visit Sochi`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            restaurantsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName} ${year}. Интересные места. Официальный сайт — Visit Sochi`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} — ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            }
          },
          place: {
            eventsPage: {
              title: ({ placeName }) => `${placeName} — афиша ${year}. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
              description: ({ placeName }) => `${placeName} — афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
              h1: ({ placeName }) => `${placeName} - афиша`,
            }
          },
          date: {
            eventsPage: {
              title: ({ localeName, date }) => `${capitalize(localeName)} — афиша на ${date} ${year} года. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
              description: ({ localeName, date }) => `${capitalize(localeName)} — афиша на ${date} ${year} года. Интересные мероприятия в городе Сочи. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
              h1: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date}`,
            }
          },
          interim: {
            eventsPage: {
              title: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} — афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
              description: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} — афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
              h1: ({ localeName, interim, formattedDate }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate}`,
            }
          }
        },
        place: {
          eventsPage: {
            title: ({ placeName }) => `${placeName} — афиша ${year}. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
            description: ({ placeName }) => `${placeName} — афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
            h1: ({ placeName }) => `${placeName} - афиша`,
          },
          streamsPage: {
            title: ({ placeName }) => `Трансляции — ${placeName} ${year}. Официальный сайт — Visit Sochi`,
            description: ({ placeName }) => `Трансляции — ${placeName} ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт — Visit Sochi`,
            h1: ({ placeName }) => `Трансляции - ${placeName}`
          },
          date: {
            eventsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} — афиша на ${date} ${year} года. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
              description: ({ placeName, date }) => `${capitalize(placeName)} — афиша на ${date} ${year} года. Интересные мероприятия в городе Сочи. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date}`
            },
            streamsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} — трансляции на ${date} ${year} года. Интересные развлекательные трансляции. Официальный сайт — Visit Sochi`,
              description: ({ placeName, date }) => `${capitalize(placeName)} — трансляции на ${date} ${year} года. Интересные трансляции города Сочи. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date}`
            }
          },
          interim: {
            eventsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} — афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} — афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate}`,
            },
            streamsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} — трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные трансляции. Официальный сайт — Visit Sochi`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} — трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные трансляции города Сочи. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate}`,
            }
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, placeName }) => `${capitalize(placeName)} — ${tagName}. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
                description: ({ tagName, placeName }) => `${capitalize(placeName)} — ${tagName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт — Visit Sochi`,
                h1: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}`,
              }
            }
          }
        },
        date: {
          eventsPage: {
            title: ({ formattedDate }) => `Афиша Сочи на ${formattedDate} года. Интересные развлекательные события в городе Сочи. Официальный сайт — Visit Sochi`,
            description: ({ formattedDate }) => `Афиша в городе Сочи на ${formattedDate} года. Интересные мероприятия города Сочи. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
            h1: ({ date }) => `Афиша на ${date}`,
          },
          moviesPage: {
            title: ({ formattedDate }) => `Киноафиша в городе Сочи на ${formattedDate}. Кино в городе Сочи — ${formattedDate}. Официальный сайт — Visit Sochi`,
            description: ({ formattedDate }) => `Кино в городе Сочи — ${formattedDate} ✅ Киноафиша в городе Сочи на ${formattedDate}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны`,
            h1: ({ date }) => `Киноафиша на ${date}`,
          },
          streamsPage: {
            title: ({ formattedDate }) => `Трансляции ${formattedDate} года. Официальный сайт — Visit Sochi`,
            description: ({ formattedDate }) => `Трансляции — ${formattedDate} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт — Visit Sochi`,
            h1: ({ date }) => `Трансляции - ${date}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, formattedDate }) => `${capitalize(tagName)} — афиша на ${formattedDate} года. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
                description: ({ tagName, formattedDate }) => `${capitalize(tagName)} — афиша на ${formattedDate} года. Интересные мероприятия города Сочи. Подробная информация о мероприятиях: описания, места проведения, расписания, ☎ телефоны`,
                h1: ({ tagName, date }) => `${capitalize(tagName)} - афиша на ${date}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, formattedDate }) => `Киноафиша в жанре ${genreName} на ${formattedDate} года в городе Сочи. Официальный сайт — Visit Sochi`,
              description: ({ genreName, formattedDate }) => `Кино в жанре ${genreName} на ${formattedDate} года в городе Сочи. Киноафиша на ${formattedDate} года в городе Сочи. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны`,
              h1: ({ genreName, date }) => `Киноафиша в жанре ${genreName} на ${date}`,
            }
          }
        },
        interim: {
          eventsPage: {
            title: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate} в городе Сочи. Интересные развлекательные события в городе Сочи. Официальный сайт — Visit Sochi`,
            description: ({ interim, formattedDate }) => `Афиша города Сочи на ${interim}, ${formattedDate}. Интересные мероприятия города Сочи. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
            h1: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate}`,
          },
          moviesPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Киноафиша на ${interim}, ${formattedDate} ${formattedYear} года в городе Сочи. Кино в Сочи ${interim}, на ${formattedDate} ${formattedYear} года. Официальный сайт — Visit Sochi`,
            description: ({ interim, formattedDate, formattedYear }) => `Кино в городе Сочи на ${interim}, ${formattedDate} ${formattedYear} года ✅ Киноафиша в городе Сочи ${interim}, ${formattedDate} ${formattedYear} года. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны`,
            h1: ({ interim, formattedDate }) => `Киноафиша на ${interim}, ${formattedDate}`,
          },
          streamsPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Официальный сайт — Visit Sochi`,
            description: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт — Visit Sochi`,
            h1: ({ interim, formattedDate }) => `Трансляции на ${interim}, ${formattedDate}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} — афиша на ${interim}, ${formattedDate} ${year} года. Интересные развлекательные события. Официальный сайт — Visit Sochi`,
                description: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} — афиша на ${interim}, ${formattedDate} ${year} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны`,
                h1: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года в городе Сочи. Официальный сайт — Visit Sochi`,
              description: ({ genreName, interim, formattedDate }) => `Кино в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года в городе Сочи. Киноафиша на ${interim} ${formattedDate} ${year} года в городе Сочи. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны`,
              h1: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate}`,
            }
          }
        },
        tag: {
          eventsPage: {
            'pushkinskaya-karta': {
              title: () => `Пушкинская карта ${year} в Сочи. Купить билеты по Пушкинской карте в городе Сочи. Официальный сайт — Visit Sochi`,
              description: () => `Афиша мероприятий по Пушкинской карте города Сочи. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия`,
              h1: () => `Пушкинская карта`
            },
            'koncerty-i-shou': {
              title: () => `Концерты в городе Сочи ${year}. Афиша концертов города Сочи. Официальный сайт — Visit Sochi`,
              description: () => `Расписания концертов в городе Сочи. Купить билеты на концерты в городе Сочи. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты`,
              h1: () => `Концерты и шоу`
            },
            'spektakli': {
              title: () => `Спектакли в городе Сочи ${year}. Купить билеты на спектакль в городе Сочи. Официальный сайт — Visit Sochi`,
              description: () => `Афиша спектаклей в городе Сочи. Купить билеты на спектакль в городе Сочи. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты`,
              h1: () => `Спектакли`
            },
            'detyam': {
              title: () => `Детская афиша в городе Сочи ${year}. Куда пойти с ребенком в Сочи. Официальный сайт — Visit Sochi`,
              description: () => `Детские мероприятия в городе Сочи. Куда пойти с ребенком в Сочи. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты`,
              h1: () => `Детям`
            },
            default: tagPageDefaultSeo
          },
          streamsPage: {
            default: {
              title: ({ tagName }) => `Трансляции — ${tagName}. Официальный сайт — Visit Sochi`,
              description: ({ tagName }) => `Трансляции — ${tagName}. Прямые трансляции: смотреть онлайн и бесплатно Официальный сайт — Visit Sochi`,
              h1: ({ tagName }) => `Трансляции - ${tagName}`,
            }
          },
          restaurantsPage: {
            default: {
              title: ({ tagName }) => `${tagName} города Сочи ${year}. Официальный сайт — Visit Sochi`,
              description: ({ tagName }) => `${tagName} в городе Сочи. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги`,
              h1: ({ tagName }) => tagName,
            }
          },
          hotelsPage: {
            default: {
              title: ({ tagName }) => `Гостиницы и отели — ${tagName} ${year}. Официальный сайт — Visit Sochi`,
              description: ({ tagName }) => `Гостиницы и отели — ${tagName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д.`,
              h1: ({ tagName }) => `Гостиницы и отели - ${tagName}`,
            }
          }
        },
        genre: {
          moviesPage: {
            title: ({ genreName }) => `Киноафиша в Сочи, жанр — ${genreName}. Кино в городе Сочи, жанр — ${genreName}. Официальный сайт — Visit Sochi`,
            description: ({ genreName }) => `Кино в Сочи, жанр — ${genreName} ✅ Киноафиша в городе Сочи, жанр — ${genreName}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны`,
            h1: ({ genreName }) => `Киноафиша, жанр - ${genreName}`
          },
        }
      },
    },
  },
  homePageBlocks: [
    'best',
    'events',
    'subscribe',
    'places',
    'overviews',
    'routes',
    'socials',
    'restaurants',
    'hotels',
    'ticketsBundles'
  ]
};
