import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ymHit } from '@kursk/components/seo/utils';

export const AnalyticsPageView = () => {
  const router = useRouter();
  const doPageView = (url) => {
    ymHit(url);
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', doPageView);
    return () => {
      router.events.off('routeChangeComplete', doPageView);
    }
  }, []);

  return null;
};
