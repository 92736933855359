import { IAddress } from "@common/redux/types/common";

const dotList = ['р-н', 'пр-кт', 'пгт', 'б-р'];
const addressLevels = [
  'region',
  'area',
  'city',
  'settlement',
  'street',
  'house'
];
export const getAddress = (address: IAddress): string | null => {
  if (!address) return '';
  return addressLevels.map((key: string) => {
    if (key === 'comment') return null;
    if (!address[key]?.name) return null;
    const dot = !dotList.includes(address[key]?.type) ? '.': ''
    const isPostfix = address[key].isPostfix;
    if (isPostfix) {
      return address[key].type ? `${address[key].name} ${address[key].type}${dot}` : `${address[key].name}`;
    }
    return address[key].type ? `${address[key].type}${dot} ${address[key].name}` : `${address[key].name}`;
  }).filter(Boolean).join(', ');
};
