import { FilterTypes } from "@common/query/queryFilter";


export class SeoBuilder {
  private path: string[];
  private args: { [x: string]: string };

  constructor() {
    this.path = [];
    this.args = {};
  }

  addFilter(name: FilterTypes, ...values: { [x: string]: string }[]) {
    this.path.push(name);
    Object.assign(this.args, ...values);
  }

  addPage(name: string) {
    this.path.push(`${name}Page`);
  }

  addPostfix(name: string) {
    this.path.push(name);
  }

  removePage() {
    this.path.pop();
  }

  get() {
    return {
      path: this.path.join('.'),
      args: this.args
    };
  }
}