import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { IHotel } from "../types/places";

interface IState {
  hotels: IHotel[]
  hotel?: IHotel;
}

export const loadHotel = createAsyncThunk<any, any>('hotelsList/loadHotel',
  async (params: any) => {
    const response = await axios.get(api.hotels.getOne(params._id));
    return {
      hotel: response.data.place,
    };
  }
);

export const hotelsListSlice = createSlice<IState, any>({
  name: 'hotelsList',
  initialState: {
    hotels: [],
  },
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadHotel.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});

export const { setActiveMobileTab } = hotelsListSlice.actions;
