import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { SliceAction } from "../types/sliceAction";

interface IState {
  [entity: string]: IMaxAndMinPrices;
}

interface IMaxAndMinPrices {
  min: number;
  max: number;
}


export const loadPrices = createAsyncThunk<IState, string>(
  `minAndMaxPrices/loadPrices`,
  async (entity) => {
    const response = await axios.get(api[entity].prices.get());
    return {
      [entity]: {
        min: 0, // Считаем, что минимальная цена билета всегда = 0
        max: response.data.max ? response.data.max / 100 : 0,
      }
    };
  });

export const getPriceSlice = createSlice<IState, any>({
  name: `minAndMaxPrices`,
  initialState: {},
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    },
    setExcursionsMinMaxPrices(state, { payload }) {
      state.excursions = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadPrices.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});

export const setExcursionsMinMaxPrices = getPriceSlice.actions.setExcursionsMinMaxPrices as SliceAction;
