export const mediaQueryBreakpoints = {
  tablet: 768,
  desktop: 1200,
};

export type Orientations = 'mobile' | 'tablet' | 'desktop';

export const DEVICE_TYPE_COOKIE_NAME = 'deviceType';

export const orientations: Record<Orientations, Orientations> = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
};

export const chars = {
  dash: '\u2014',
  infinity: '\u221e',
  nbsp: '\u00a0',
  hairsp: '\u200a',
  rouble: '\u20bd',
  minus: '\u2212'
};
