import moment from 'moment';
import { getAddress } from "@kursk/utils/getAddress";
import { IStream } from "@common/redux/types/invoices";
import { IAddress } from "@common/redux/types/common";

const getStreamAddress = (entity: IStream): IAddress => {
  let address: IAddress = null;
  if (entity.affiliation.type === 'location') address = entity.affiliation?.location?.address;
  if (entity.affiliation.type === 'place') address = entity.affiliation?.place?.address;
  if (entity.affiliation.type === 'entity') {
    address = entity.affiliation?.seance?.venue?.location?.address || entity.affiliation?.seance?.venue?.place?.address;
  }
  return address;
}

export const getStreamTitle = (entity: IStream): string => {
  return `${entity.name} — описание, дата, время, продолжительность. ` +
    `Адрес Места проведения — ${getAddress(getStreamAddress(entity))}.`;
}

export const getStreamDescription = (entity: IStream, siteName: string): string => {
  let placeName: string = null;
  if (entity.affiliation.type === 'place') placeName = entity.affiliation?.place?.name;
  if (entity.affiliation.type === 'entity' && entity.affiliation?.seance?.venue?.place) {
    placeName = entity.affiliation?.seance?.venue?.place?.name;
  }

  return `${entity.name}, ${placeName ? `Место проведения — ${placeName}, ` : ''}` +
    `дата проведения — ${entity.start ? moment(entity.start).format('D MMMM YYYY HH:mm') : ''}, ` +
    `адрес места проведения — ${getAddress(getStreamAddress(entity))}. ` +
    `Описание трансляции. Официальный сайт - ${siteName}`;
}

export function getStreamPlaceOrEvent(entity: { placeName?: string, address?: string, eventName?: string }): string {
  if (entity.placeName) {
    return `Место проведения — ${entity.placeName}. `;
  }
  if (entity.address) {
    return `Место проведения — ${entity.address}. `;
  }
  if (entity.eventName) {
    return `Событие — ${entity.eventName}. `;
  }
  return '';
}

export function getGenres(entity: { genres }): string {
  return entity?.genres.map(genre => genre.name).join(', ')
}

export function getCountries(entity: { countries }): string {
  return entity?.countries.join(', ')
}

export function getReleaseDate(entity: { releaseDate }): string {
  return moment(entity?.releaseDate).format('D MMMM YYYY')
}

export function getMovieDescription(entity: { genres, creationYear, countries, releaseDate }): string {
  const { genres, creationYear, countries, releaseDate } = entity;
  const descriptionArray = [];

  if (genres && genres.length > 0) descriptionArray.push(` жанр - ${getGenres(entity)}`)
  if (creationYear) descriptionArray.push(`год - ${creationYear}`)
  if (countries && countries.length > 0) descriptionArray.push(`страна - ${getCountries(entity)}`)
  if (releaseDate) descriptionArray.push(`в кино с ${getReleaseDate(entity)}`)
  return descriptionArray.join(', ')
}

