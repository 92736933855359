import { TFavoritesState } from "../../types/favorites";

export const initialState: TFavoritesState = {
  isFavorite: false,
  places: {
    items: [],
    total: 0,
    offset: 0,
  },
  events: {
    items: [],
    total: 0,
    offset: 0,
  },
  tours: {
    items: [],
    total: 0,
    offset: 0,
  },
  excursions: {
    items: [],
    total: 0,
    offset: 0,
  },
  touristroutes: {
    items: [],
    total: 0,
    offset: 0,
  },
  restaurants: {
    items: [],
    total: 0,
    offset: 0,
  },
  overviews: {
    items: [],
    total: 0,
    offset: 0,
  },
};
