import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { IFeedback, IFeedbackEntity, IFeedbackRequest } from "../types/feedbacks";

interface IState {
  canSendFeedback: boolean;
  feedbacksList?: {
    feedbacks?: IFeedback[],
    total?: number,
  };
}

export const loadFeedbacks = createAsyncThunk<any,IFeedbackEntity,{ state: IState }>(
  'feedbacksSlice/loadFeedbacks', async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.reviews.get(), { params: { ...params } });
      return {
        feedbacksList: {
          feedbacks: response.data.reviews,
          total: response.data.total,
        }
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  });

export const checkCanSendFeedback =
  createAsyncThunk<any, IFeedbackEntity, { state: IState }>(
    'feedbacksSlice/checkCanSendFeedback', async (params, { rejectWithValue }) => {
      try {
        const response = await axios.put(api.reviews.canWrite(), { ...params });
        return {
          canSendFeedback: response.data.result,
        }
      } catch (error) {
        return rejectWithValue(error);
      }
    });

export const createFeedback = createAsyncThunk<any,IFeedbackRequest,{ state: IState }>(
  'feedbacksSlice/createFeedback',
  async (data, { getState, dispatch }) => {
    const oldFeedbacksList = (getState()['feedbacks'] as IState).feedbacksList;
    const response = await axios.post(api.reviews.create(), data);
    dispatch(checkCanSendFeedback(data.entity));
    return {
      feedbacksList: {
        feedbacks: [
          response.data.review,
          ...oldFeedbacksList.feedbacks,
        ],
        total: oldFeedbacksList.total + 1,
      }
    };
  });

export const feedbacksSlice = createSlice<IState, any>({
  name: 'feedbacks',
  initialState: {
    canSendFeedback: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createFeedback.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(loadFeedbacks.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(checkCanSendFeedback.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});
