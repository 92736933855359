import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from "../../urls";

interface IState {
  isSending: boolean;
}

export const writeToUsThunk = createAsyncThunk<any, any>('writeToUsForm/writeToUsThunk', async ({ email, message }) => {
  await axios.put(api.feedback.update(), { email, message });
});

export const writeToUsFormSlice = createSlice<IState, any>({
  name: 'writeToUsForm',
  initialState: {
    isSending: false,
  },
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(writeToUsThunk.pending, (state) => {
      state.isSending = true;
    });
    builder.addCase(writeToUsThunk.fulfilled, (state) => {
      state.isSending = false;
    });
  }
});

export const loadSubscribeStatus = writeToUsFormSlice.actions;