import _ from 'lodash';
import queryString from 'query-string';
import { isServer } from "../../utils/server/isServer";
import { getConfig } from "../../config/config";

export const api = {
  me: {
    get: () => apiUrl('/me'),
    update: () => apiUrl('/me/update'),
  },
  auth: {
    mobile: () => apiUrl('/auth/mobile'),
    register: () => apiUrl('/auth/register'),
    authPhone: () => apiUrl('/auth/auth-phone'),
    confirmRegisterPhone: () => apiUrl('/auth/confirm-register-phone'),
    confirmAuthPhone: () => apiUrl('/auth/confirm-auth-phone'),
    registerPhone: () => apiUrl('/auth/register-phone'),
    restorePassword: () => apiUrl('/auth/restore-password'),
    confirmRestore: () => apiUrl('/auth/confirm-restore'),
    changeEmail: () => apiUrl(`/auth/change-email`),
    changePassword: () => apiUrl(`/auth/change-password`)
  },
  files: {
    upload: () => `/files/upload`,
  },
  picks: {
    get: () => apiUrl('/picks'),
    getOne: (id: number) => apiUrl(`/picks/${id}`),
  },
  feedback: {
    update: () => apiUrl('/feedback'),
  },
  subscribers: {
    create: () => apiUrl('/subscribers'),
  },
  subscription: {
    subscribeToStream: () => apiUrl('/subscription/subscribe-to-stream'),
  },
  events: {
    get: () => apiUrl('/events'),
    getOne: (id: number) => apiUrl(`/events/${id}`),
    prices: {
      get: () => apiUrl('/events/prices'),
    },
    eventsSeances: {
      get: (id: number) => apiUrl(`/events/${id}/seances`)
    },
    eventsSchedules: {
      get: (id: number) => apiUrl(`/events/${id}/schedules`)
    },
    getEventsPlaces: () => apiUrl('/events/places'),
  },
  tags: {
    get: () => apiUrl('/tags')
  },
  locales: {
    get: () => apiUrl('/locales'),
    getOne: (id: number) => apiUrl(`/locales/${id}`),
  },
  recommendations: {
    events: {
      get: () => apiUrl('/recomendations/events'),
    },
    routes: {
      get: () => apiUrl('/recomendations/routes'),
    },
    touristRoutes: {
      get: () => apiUrl('/recomendations/touristRoutes'),
    },
    overviews: {
      get: () => apiUrl('/recomendations/overviews')
    },
    places: {
      get: () => apiUrl('/recomendations/places')
    },
    forMovies: {
      movies: {
        get: (id: number) => apiUrl(`/recomendations/forMovie/${id}/movies`),
      }
    },
    forTicketsBundles: {
      get: (id: number) => apiUrl(`/recomendations/forTicketsBundles/${id}/bundles`)
    },
    forEvent: {
      events: {
        get: (id: number) => apiUrl(`/recomendations/forEvent/${id}/events`)
      },
      places: {
        get: (id: number) => apiUrl(`/recomendations/forEvent/${id}/places`)
      }
    },
    forTouristRoutes: {
      get: (id: number) => apiUrl(`/recomendations/forTouristRoutes/${id}/touristRoutes`)
    },
    forStreamsStreams: {
      get: (id: number) => apiUrl(`/recomendations/forStreams/${id}/streams`)
    },
    forArticle: {
      get: (id: number) => apiUrl(`/recomendations/forArticle/${id}/regions`),
      artefacts: {
        get: (id: number) => apiUrl(`/recomendations/forArticle/${id}/artefacts`)
      },
    },
    forOverviews: {
      overviews: {
        get: (id: number) => apiUrl(`/recomendations/forOverviews/${id}/overviews`)
      },
      routes: {
        get: (id: number) => apiUrl(`/recomendations/forOverviews/${id}/routes`)
      },
    },
    forNews: {
      news: {
        get: (id: number) => apiUrl(`/recomendations/forNews/${id}/news`)
      },
    },
    forPlaces: {
      events: {
        get: (id: number) => apiUrl(`/recomendations/forPlaces/${id}/events`)
      },
      streams: {
        get: (id: number) => apiUrl(`/recomendations/forPlaces/${id}/streams`)
      },
      places: {
        get: (id: number) => apiUrl(`/recomendations/forPlaces/${id}/places`)
      },
      virtualHalls: {
        get: (id: number) => apiUrl(`/recomendations/forPlaces/${id}/virtualHalls`)
      },
      virtualMuseums: {
        get: (id: number) => apiUrl(`/recomendations/forPlaces/${id}/virtualMuseums`)
      },
    },
    forRestaurants: {
      placesNear: {
        get: (id: number) => apiUrl(`/recomendations/forRestaurants/${id}/placesNear`)
      },
      restaurants: {
        get: (id: number) => apiUrl(`/recomendations/forRestaurants/${id}/restaurants`)
      }
    },
    forHotels: {
      placesNear: {
        get: (id: number) => apiUrl(`/recomendations/forHotels/${id}/placesNear`)
      },
      restaurantsNear: {
        get: (id: number) => apiUrl(`/recomendations/forHotels/${id}/restaurantsNear`)
      },
      hotels: {
        get: (id: number) => apiUrl(`/recomendations/forHotels/${id}/hotels`)
      }
    },
    pushkinPlaces: {
      get: () => apiUrl('/recomendations/places/pushkin'),
    },
    forEvents: { // УБРАТЬ!!
      places: {
        get: () => apiUrl('/recomendations/places')
      },
      overviews: {
        get: () => apiUrl('/recomendations/overviews')
      }
    }
  },
  places: {
    get: () => apiUrl('/places'),
    getOne: (id: number) => apiUrl(`/places/${id}`),
    prices: {
      get: () => apiUrl('/places/prices'),
    },
    points: {
      get: () => apiUrl('/places/points'),
    },
  },
  restaurants: {
    getOne: (id: number) => apiUrl(`/places/${id}`),
    get: () => apiUrl('/places'),
    prices: {
      get: () => apiUrl('/restaurants/prices'),
    },
  },
  hotels: {
    getOne: (id: number) => apiUrl(`/places/${id}`),
    get: () => apiUrl('/places'),
    prices: {
      get: () => apiUrl('/hotels/prices'),
    },
  },
  articles: {
    getOne: (id: number) => apiUrl(`/articles/${id}`),
    get: () => apiUrl('/articles')
  },
  touristroutes: {
    getOne: (id: number) => apiUrl(`/touristroutes/${id}`),
    get: () => apiUrl('/touristroutes'),
    maxDuration: {
      get: () => apiUrl('/touristroutes/maxDuration')
    }
  },
  hints: {
    get: () => apiUrl(`/search/hints?`)
  },
  faq: {
    getFAQ: () => apiUrl(`/faqs`)
  },
  overviews: {
    get: () => apiUrl('/articles')
  },
  news: {
    get: () => apiUrl('/articles')
  },
  routes: {
    get: () => apiUrl('/articles')
  },
  invoices: {
    get: () => apiUrl('profile/invoices'),
  },
  streams: {
    get: () => apiUrl('/streams'),
    getOne: (id: number) => apiUrl(`/streams/${id}`),
    getStreamsPlaces: () => apiUrl('/streams/places'),
  },
  ticketsbundles: {
    getOne: (id: number) => apiUrl(`/ticketsbundles/${id}`),
    get: () => apiUrl('/ticketsbundles'),
    prices: {
      get: () => apiUrl('/ticketsbundles/prices'),
    },
  },
  banners: {
    get: () => apiUrl(`/banners`)
  },
  globalConfig: () => apiUrl('/globalConfig'),
  sprojects: () => apiUrl('/sprojects'),
  movies: {
    get: () => apiUrl('/movies'),
    getOne: (id: number) => apiUrl(`/movies/${id}`),
  },
  genres: {
    get: () => apiUrl(`/genres`)
  },
  reviews: {
    get: () => apiUrl('/reviews'),
    create: () => apiUrl('/reviews'),
    canWrite: () => apiUrl('/reviews/can-write-review'),
  },
  mapPoints: {
    get: () => apiUrl('/map'),
    getOne: () => apiUrl(`/map/detail`)
  },
  search: {
    get: () => apiUrl('/search')
  },
  weather: {
    get: () => apiUrl('/weather'),
  },
  excursions: {
    get: () => apiUrl('/excursions'),
    getOne: (id: number) => apiUrl(`/excursions/${id}`),
    prices: {
      get: () => apiUrl('/excursions/prices'),
    },
    excursionSeances: {
      get: (id: number) => apiUrl(`/excursions/${id}/seances`)
    },
    booking: {
      create: (entityId, seanceId) => apiUrl(`/excursions/${entityId}/seances/${seanceId}/bookings`)
    }
  },
  tours: {
    get: () => apiUrl('/tours'),
    getOne: (id: number) => apiUrl(`/tours/${id}`),
    prices: {
      get: () => apiUrl('/tours/prices'),
    },
    tourSeances: {
      get: (id: number) => apiUrl(`/tours/${id}/seances`)
    },
    booking: {
      create: (entityId, seanceId) => apiUrl(`/tours/${entityId}/seances/${seanceId}/bookings`)
    }
  },
  organizations: {
    get: () => apiUrl('/organizations')
  },
  goods: {
    get: () => apiUrl('/goods')
  },
  favorites: {
    get: () => apiUrl('/favorites'),
    check: () => apiUrl('/favorites/check'),
    post: () => apiUrl('/favorites'),
    delete: () => apiUrl('/favorites/delete'),
  },
  guideGo: {
    getOne: (id: string) => apiUrl(`/guidego/${id}`),
  }
}

export const apiUrl = (url) => {
  if (url[0] !== '/') url = `/${url}`;
  return `${isServer() ? getConfig(false).api.innerUrl : ''}/api/1.0${url}`;
}

export const paramsSerializer = (params) => queryString.stringify(
  _(params).mapValues((value) => {
    if (_(value).isArray()) {
      return value.join(',');
    } else {
      return value;
    }
  }).omitBy(_.isUndefined).value()
);

export const filtersToQuery = (object: any) => _(object)
  .mapValues((filter) => _(filter).isArray() ? filter.join(',') : filter)
  .pickBy((filter) => filter || _(filter).isBoolean() || _(filter).isNumber())
  .value();

export const sortToQuery = (sort: any) => {
  if (_(sort).isObject()) {
    return _(sort).map((direction, field) => (direction > 0 ? '' : '-') + field).value();
  } else {
    return sort;
  }
};

export const paramsToQuery = (params) => {
  const transformed = filtersToQuery(params);

  if (transformed.sort) {
    transformed.sort = sortToQuery(transformed.sort);
  }

  return transformed;
};
