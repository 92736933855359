import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from "../../urls";
import { isServer } from "../../../../utils/server/isServer";
import { SliceAction } from "../types/sliceAction";

const SUBSCRIPTION_ID = 'formSubscriptions';

interface IState {
  status: boolean,
  streams: number[],
}

const getSubscriptions = () => {
  if (isServer()) {
    return;
  }
  try {
    const data = window?.localStorage?.getItem(SUBSCRIPTION_ID);
    return data ? JSON.parse(data) : {};
  } catch (e) {
    return {};
  }
}

const setSubscriptions = (data: Partial<IState>) => {
  if (isServer()) {
    return;
  }
  const oldData = getSubscriptions();
  window?.localStorage?.setItem(SUBSCRIPTION_ID, JSON.stringify({ ...oldData, ...data }));
}

export const subscribeThunk = createAsyncThunk<any, any>('subscriptionForm/subscribeThunk', async ({
  email,
  from = 'footer',
}) => {
  await axios.post(api.subscribers.create(), { email, from, referer: window.location.href });
});

export const subscribeStream = createAsyncThunk<any, any>('subscriptionForm/subscribeStreams', async ({
  email,
  streamId
}) => {
  await axios.put(api.subscription.subscribeToStream(), { email, referer: window.location.href, stream: streamId });
  return streamId;
});

export const subscriptionFormSlice = createSlice<IState, any>({
  name: 'subscriptionForm',
  initialState: {
    status: false,
    streams: [],
  },
  reducers: {
    loadStatus: (state) => {
      Object.assign(state, getSubscriptions());
    },
  },
  extraReducers: builder => {

    builder.addCase(subscribeThunk.fulfilled, (state) => {
      state.status = true;
      setSubscriptions({ status: true });
      Object.assign(state, getSubscriptions());
    });

    builder.addCase(subscribeStream.fulfilled, (state, { payload }) => {
      const oldStreams = state.streams || [];
      setSubscriptions({ streams: [...oldStreams, payload] });
      Object.assign(state, getSubscriptions());
    });
  }
});

export const loadSubscribeStatus = subscriptionFormSlice.actions.loadStatus as SliceAction;
