import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from "../../urls";
import { ITicketsBundles } from "../types/ticketsBundles";

interface IState {
  ticketsBundle?: ITicketsBundles;
}

export const loadTicketsBundle = createAsyncThunk<any, any>('ticketsBundlesList/loadTicketsBundle',
  async (params: {_id: number}) => {
    const response = await axios.get(api.ticketsbundles.getOne(params._id));
    return {
      ticketsBundle: response.data.ticketsBundle || null,
    };
  });

export const ticketsBundleSlice = createSlice<IState, any>({
  name: 'ticketsBundlesList',
  initialState: {
  },
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(loadTicketsBundle.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});