import { ParsedUrlQuery } from "querystring";
import { keyBy } from "lodash";
import { IFilters, IMeta } from "../redux/slices/entities.slice";

export function createQueryFromFilters(filters: IFilters, filterNames: string [], meta: IMeta): ParsedUrlQuery {
  const places = filters.places;
  const locales = filters.locales;
  const isFree = filters.isFree;
  const withTickets = filters.withTickets;
  const hasAudio = filters.hasAudio;
  const maxPrice = filters.maxPrice;
  const minPrice = filters.minPrice;
  const priceSource = filters.priceSource;
  const isLive = filters.isLive;
  const isPreview = filters.isPreview;
  const isArchive = filters.isArchive;
  const routeTypes = filters.routeTypes;
  const durationTo = filters.durationTo;
  const durationFrom = filters.durationFrom;
  const start = filters.start;
  const end = filters.end;
  const allTags = filters.allTags;
  const genres = filters.genres;
  const transportationMethods = filters.transportationMethods;
  const organizations = filters.organizations;

  let selectedAllTags = [];
  let selectedGenres = [];

  if (filterNames.includes('allTags') && allTags?.length) {
    const metaTags = keyBy(meta.tags, '_id');
    selectedAllTags = allTags.map((sysName) => metaTags[sysName]?.sysName).filter(Boolean);
  }

  if (filterNames.includes('genres') && genres?.length) {
    const metaGenres = keyBy(meta.genres, '_id');
    selectedGenres = genres.map((sysName) => metaGenres[sysName]?.sysName).filter(Boolean);
  }

  return {
    ...filterNames.includes('locales') && locales?.length && { locales: locales.join(',') },
    ...filterNames.includes('places') && places?.length && { places: places.join(',') },
    ...filterNames.includes('isFree') && isFree && { isFree },
    ...filterNames.includes('withTickets') && withTickets && { withTickets },
    ...filterNames.includes('hasAudio') && hasAudio && { hasAudio },
    ...filterNames.includes('priceSource') && priceSource && { priceSource },
    ...filterNames.includes('maxPrice') && maxPrice && { maxPrice },
    ...filterNames.includes('minPrice') && minPrice && { minPrice },
    ...filterNames.includes('isLive') && isLive && { isLive },
    ...filterNames.includes('isPreview') && isPreview && { isPreview },
    ...filterNames.includes('isArchive') && isArchive && { isArchive },
    ...filterNames.includes('routeTypes') && routeTypes?.length && { routeTypes: routeTypes.join(',') },
    ...filterNames.includes('durationTo') && durationTo && { durationTo },
    ...filterNames.includes('durationFrom') && durationFrom && { durationFrom },
    ...filterNames.includes('start') && start && { start },
    ...filterNames.includes('end') && end && { end },
    ...filterNames.includes('transportationMethods') &&
    transportationMethods?.length && { transportationMethods: transportationMethods.join(',') },
    ...filterNames.includes('organizations') && organizations?.length && { organizations: organizations.join(',') },
    ...selectedAllTags?.length && { tags: selectedAllTags.join(',') },
    ...selectedGenres?.length && { genres: selectedGenres.join(',') }
  } as unknown as ParsedUrlQuery;
}
