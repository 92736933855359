import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { IPlace } from '../types/places';

interface IState {
  places: IPlace[]
  place?: IPlace;
}

export const loadPlace = createAsyncThunk<any, any>('placesList/loadPlace',
  async (params: any) => {
    const response = await axios.get(api.places.getOne(params._id));
    return {
      place: response?.data?.place || null,
    };
  }
);

export const placesListSlice = createSlice<IState, any>({
  name: 'placesList',
  initialState: {
    places: [],
    place: null,
  },
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadPlace.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});

export const { setActiveMobileTab } = placesListSlice.actions;
