import React from 'react';
import Router from 'next/router';

interface IProps {
  renderLoader: () => JSX.Element
}

export class GlobalLoader extends React.Component<IProps> {
  constructor(props) {
    super(props);

    this.setLoadingState = this.setLoadingState.bind(this);
    this.removeLoadingState = this.removeLoadingState.bind(this);

    Router.events.on('routeChangeStart', this.setLoadingState);
    Router.events.on('routeChangeComplete', this.removeLoadingState);
    Router.events.on('routeChangeError', this.removeLoadingState);
  }
  state: {
    isLoading: false
  }
  setLoadingState() {
    document.body.classList.add('no-scroll');
    this.setState({ isLoading: true });
  }

  removeLoadingState() {
    document.body.classList.remove('no-scroll');
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.setLoadingState);
    Router.events.off('routeChangeComplete', this.removeLoadingState);
    Router.events.off('routeChangeError', this.removeLoadingState);
  }

  public render() {
    return (<>
      {this.state?.isLoading && this.props.renderLoader()}
    </>);
  }
}
