import moment from "moment";

const monthDictionary = {
  'yanvarya': 1,
  'fevralya': 2,
  'marta': 3,
  'aprelya': 4,
  'maya': 5,
  'iyunya': 6,
  'iyulya': 7,
  'avgusta': 8,
  'sentyabrya': 9,
  'oktyabrya': 10,
  'noyabrya': 11,
  'dekabrya': 12
}

export function getDateByWordForm(wordform: string) {
  if (!wordform.match(/\d+-\w+/)) {
    throw new Error('Invalid date');
  }

  const [dateOfMonth, month] = wordform.split('-');

  const monthNum = monthDictionary[month];

  let date = moment(`${dateOfMonth} ${monthNum}`, 'D M');
  if (date < moment()) {
    date = moment(`${dateOfMonth} ${monthNum} ${moment().year()+1}`, 'D M YYYY');
  }

  return {
    start: date.startOf('day').valueOf(),
  };
}

export function getWordFormByDate(date: number) {
  const momentDate = moment(date);
  const monthNum = Number(momentDate.format('M'));
  const month = Object.keys(monthDictionary).find((k) => monthDictionary[k] === monthNum);

  return `${momentDate.format('D')}-${month}`;
}

export const ruInterims = {
  today: 'сегодня',
  tomorrow: 'завтра',
  weekend: 'выходные'
}