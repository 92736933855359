var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { getConfig } from '../../config/config';

Sentry.init({
  dsn: getConfig().sentry.dsn,
});

Sentry.setTag('app', 'portal client');
Sentry.setTag('region', getConfig().theme);
