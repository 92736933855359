import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from "../../urls";

interface IState {
  isSending: boolean;
}

export const bookingThunk = createAsyncThunk<any, any>('bookingSlice/bookingThunk',
  async ({ type, values }: {
    type: 'excursions' | 'tours',
    values: {
      entityId: number;
      seanceId: number;
      userName: string;
      email: string;
      phone: string;
      adultsNumber: number;
      childrenNumber: number;
    }
  }
  ) => {
    await axios.post(api[type].booking.create(values.entityId, values.seanceId), values).then();
  });

export const bookingSlice = createSlice<IState, any>({
  name: 'bookingSlice',
  initialState: {
    isSending: false,
  },
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(bookingThunk.pending, (state) => {
      state.isSending = true;
    });
    builder.addCase(bookingThunk.fulfilled, (state) => {
      state.isSending = false;
    });
    builder.addCase(bookingThunk.rejected, (state) => {
      state.isSending = false;
    });
  }
});

export const loadSubscribeStatus = bookingSlice.actions;