import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { IExcursion } from '../types/excursion';
import { TGuideGoExcursion } from "../types/excursion-guidego";
import { SliceAction } from "../types/sliceAction";

export interface IExcursionState {
  excursion?: IExcursion;
  excursionGuideGo?: TGuideGoExcursion;
  durations?: { min: number, max: number }
}

export const loadExcursion = createAsyncThunk<any, any>('excursionSlice/loadExcursion',
  async (params: any) => {
    const newParams = {
      ...params,
    };
    const response = await axios.get(api.excursions.getOne(params._id), { params: newParams });
    return {
      excursion: response.data.excursion,
    };
  }
);

export const loadGuideGoExcursion = createAsyncThunk<any, any>('excursionSlice/loadGuideGoExcursion',
  async (_id: string) => {
    const response = await axios.get(api.guideGo.getOne(_id));
    return {
      excursionGuideGo: response.data,
    };
  }
);

export const loadDurations = createAsyncThunk<any, never>(
  `excursionSlice/loadDurations`,
  async () => {
    const { data } = await axios.get(api.excursions.get(), { params: { meta: 'durations', limit: 1 } });
    const { durations } = data.meta;
    return {
      durations: {
        min: durations.min ? Math.floor(durations.min / 60) : 0,
        max: durations.max ? Math.ceil(durations.max / 60) : 0,
      }
    };
  });

export const excursionSlice = createSlice<IExcursionState, any>({
  name: 'excursionSlice',
  initialState: {
    excursionGuideGo: {},
    durations: {
      min: 0,
      max: 0,
    }
  } as IExcursionState,
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    },
    setDurations(state, { payload }) {
      state.durations = payload
    }
  },
  extraReducers: builder => {
    builder.addCase(loadExcursion.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(loadGuideGoExcursion.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(loadDurations.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});

export const setDurations = excursionSlice.actions.setDurations as SliceAction;
