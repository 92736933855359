import { isStartDateInterim } from "./queryFilter";
import { IFilters, IMeta } from "../redux/slices/entities.slice";
import { EntityType } from "../redux/types/common";
import { getWordFormByDate } from "../utils/dateWordForms";

const prefixes = {
  places: 'place',
  locales: 'district',
  allTags: 'tag',
  genres: 'genre'
};

const getFilterSysname = (item: number, stateItems: any[]) => {
  return stateItems.find(({ _id }) => _id === item)?.sysName || '';
}


function addSysnameToFilters(urlFilters: string[], name: string, item: any, container: any): void {
  if (item) {
    const sysName = getFilterSysname(item, container);
    if (sysName) {
      urlFilters.push(`${prefixes[name]}-${sysName}`);
    }
  }
}

export function getUserFriendlyURL(filters: IFilters, entityType: EntityType, meta: IMeta, state: any): string {
  const { allTags = [], genres = [], places = [], locales = [], start = null, end = null } = filters;

  const urlFilters = [];

  // Порядок важен, зависит от приоритетов фильтров в URL
  // Тэги
  addSysnameToFilters(urlFilters, 'allTags', allTags[0], meta.tags)
  addSysnameToFilters(urlFilters, 'genres', genres[0], meta.genres);
  
  // Интерим
  const interim = isStartDateInterim(start, end);
  if (interim) {
    urlFilters.push(interim);
  }
  // Район
  addSysnameToFilters(urlFilters, 'locales', locales[0], state.locales.items)
  // Место
  addSysnameToFilters(
    urlFilters, 'places', places[0],
    entityType === 'events' ? state.eventsPlaces.items : state.streamsPlaces.items
  )
  // Дата
  if (!interim && start && !end) {
    const date = getWordFormByDate(start);
    if (date) {
      urlFilters.push(`date-${date}`);
    }
  }
  // Первые два фильтра
  urlFilters.splice(2);

  return `${entityType}${urlFilters.map(f => `/${f}`).join('')}`;
}