import moment from 'moment';
import { ParsedUrlQuery } from 'querystring';

const interims = ['today', 'tomorrow', 'weekend'];
export type Interim = 'today' | 'tomorrow' | 'weekend';

const isFilterInterim = (filter: string) => interims.includes(filter);
const isFilterLocale = (filter: string) => Boolean(filter.match(/^district-/));
const isFilterPlace = (filter: string) => Boolean(filter.match(/^place-/));
const isFilterDate = (filter: string) => Boolean(filter.match(/^date-\d*/));
const isFilterTag = (filter: string) => Boolean(filter.match(/^tag-/));
const isFilterGenre = (filter: string) => Boolean(filter.match(/^genre-/));

export enum FilterTypes {
  INTERIM = 'interim',
  LOCALE = 'locale',
  PLACE = 'place',
  DATE = 'date',
  TAG = 'tag',
  GENRE = 'genre',
}

export type Filter = {
  type?: FilterTypes;
  interim?: Interim;
  locale?: string;
  place?: string;
  date?: string;
  tag?: string;
  genre?: string;
}

function getFilter(filter: string) {
  for (const { checker, getValue, name } of [
    { checker: isFilterInterim, getValue: (v: string) => v, name: FilterTypes.INTERIM },
    { checker: isFilterLocale, getValue: (v: string) => v.slice(9), name: FilterTypes.LOCALE },
    { checker: isFilterPlace, getValue: (v: string) => v.slice(6), name: FilterTypes.PLACE },
    { checker: isFilterDate, getValue: (v: string) => v.slice(5), name: FilterTypes.DATE },
    { checker: isFilterTag, getValue: (v: string) => v.slice(4), name: FilterTypes.TAG },
    { checker: isFilterGenre, getValue: (v: string) => v.slice(6), name: FilterTypes.GENRE },
  ]) {
    if (checker(filter)) {
      return {
        type: name,
        [name]: getValue(filter)
      }
    }
  }

  return null;
}

export type QueryFilters = [Filter, Filter] | [Filter] | null;

export function getQueryFilter(query: ParsedUrlQuery): QueryFilters {
  let queryFilters = query.filter as string[];

  if (typeof queryFilters === 'string') {
    queryFilters = [queryFilters];
  }
  
  if (!queryFilters || !queryFilters.length) {
    return null;
  }

  const result: Filter[] = [];

  for (const queryFilter of queryFilters.slice(0, 2)) {
    const filter = getFilter(queryFilter);
    if (filter) {
      result.push(filter);
    }
  }

  if (!result.length) {
    return null;
  }

  return result as unknown as [Filter];
}

export function filtersHas(type: FilterTypes, newFilters: QueryFilters) {
  if (!newFilters || !newFilters.length) {
    return false;
  }
  for (const filter of newFilters) {
    if (filter.type === type && filter[type]) {
      return true;
    }
  }

  return false;
}

export function filtersGet(type: FilterTypes, newFilters: QueryFilters) {
  if (!newFilters || !newFilters.length) {
    return '';
  }
  for (const filter of newFilters) {
    if (filter.type === type && filter[type]) {
      return filter[type];
    }
  }

  return '';
}

export function isStartDateInterim(start: number, end: number): Interim | null {
  if (moment().startOf('day').isSame(start) && !end) {
    return 'today';
  }

  if (moment().add(1, 'day').startOf('day').isSame(start) && !end) {
    return 'tomorrow';
  }

  if (
    moment().weekday(5).startOf('day').isSame(start) &&
    moment().weekday(6).startOf('day').isSame(end)
  ) {
    return 'weekend';
  }

  return null;
}

export function getStartEndByInterim(interim: Interim): { start: number, end?: number } | null {
  if (interim === 'today') {
    return { start: moment().startOf('day').valueOf() };
  }

  if (interim === 'tomorrow') {
    return { start: moment().add(1, 'day').startOf('day').valueOf() };
  }

  if (interim === 'weekend') {
    return {
      start: moment().weekday(5).startOf('day').valueOf(),
      end: moment().weekday(6).startOf('day').valueOf()
    }
  }

  return null;
}