import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { IArticle } from "../types/articles";
import { IRange } from "../../../kursk/components/ui/Filters/RangeFilter/RangeFilter";

interface IState {
  touristRoute?: IArticle;
  rangeValues?: IRange;
}

export const loadRoute = createAsyncThunk<IState, { id: number }>('touristRoutes/loadRoute',
  async (params: { id: number }) => {
    const response = await axios.get(api.touristroutes.getOne(params.id));
    return {
      touristRoute: response.data.touristRoute || null,
    };
  }
);

export const loadDurations = createAsyncThunk<IState, any>(
  `touristRoutes/loadDurations`,
  async () => {
    const response = await axios.get(api.touristroutes.maxDuration.get());

    return {
      rangeValues: {
        min: 0, // Считаем, что минимальное значение
        max: response.data.max ? response.data.max : 0,
      }
    };
  });

export const touristRoutesSlice = createSlice<IState, any>({
  name: 'touristRoutes',
  initialState: {},
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadRoute.fulfilled, (state, { payload }) => Object.assign(state, payload))
      .addCase(loadDurations.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});
