import moment from 'moment';
import { regionContent } from "@kursk/content/regionContent";
import { getAddress } from "@kursk/utils/getAddress";
import { getImageUrl } from "@common/utils/image";
import { getConfig } from "@root/config/config";
import { EntityType, ILocale } from "@common/redux/types/common";
import { IEvent } from "@common/redux/types/event";
import { IPlace } from "@common/redux/types/places";
import { IArticle } from "@common/redux/types/articles";
import {
  FilterTypes, Interim, QueryFilters, filtersGet, filtersHas, getStartEndByInterim
} from '@common/query/queryFilter';
import { Page } from './seo.types';
import { ITag } from '@common/redux/types/tags';
import { getDateByWordForm, ruInterims } from '@common/utils/dateWordForms';
import { get } from 'lodash';
import { SeoBuilder } from './builder';

const daysOfWeek = {
  1: 'http://schema.org/Monday',
  2: 'http://schema.org/Tuesday',
  3: 'http://schema.org/Wednesday',
  4: 'http://schema.org/Thursday',
  5: 'http://schema.org/Friday',
  6: 'http://schema.org/Saturday',
  7: 'http://schema.org/Sunday',
};

export const workingTimeToString = (workingTime) =>
  moment.utc(workingTime).format('HH:mm');

export const workingDayToString = (day, format = 'dddd') =>
  moment().isoWeekday(Number(day)).format(format);

const getGeneralParams = (entity, type, baseUrl) => ({
  ['@context']: 'https://schema.org',
  ['@type']: `${type?.slice(0, 1)?.toUpperCase()}${type?.slice(1, -1)}`,
  name: entity?.name,
  description: entity?.description,
  url: `${baseUrl}/${type}/${entity?._id}/${entity?.sysName}`,
  image: {
    ['@type']: 'ImageObject',
    url: getImageUrl(entity?.image),
    caption: entity?.image?.sourceTitle,
  },
});

export const getLocality = (address) => {
  if (!address || !address?.region) return '';
  return address?.region.isPostfix ? `${address?.region.name} ${address?.region.type}` :
    `${address?.region.type} ${address?.region.name}`;
};

export const getRegion = (address) => {
  if (!address || !address?.area) return '';
  return address?.area.isPostfix ? `${address?.area.name} ${address?.area.type}` :
    `${address?.area.type} ${address?.area.name}`;
}

export const getStreetAddress = (adress) => {
  if (!adress || !adress?.street) return '';
  return `${adress?.street.name}${adress?.house ? `, д. ${adress?.house.name}` : ''}`;
};

const getGeo = (mapPosition, address) => {
  if (mapPosition || mapPosition?.type === 'Point') {
    const [latitude, longitude] = mapPosition.coordinates;
    return {
      geo: {
        ['@type']: 'GeoCoordinates',
        address: getAddress(address),
        addressCountry: 'RU',
        latitude,
        longitude,
      },
    };
  }
  if (mapPosition || mapPosition?.type === 'MultiPoint') {
    const [latitude, longitude] = mapPosition.coordinates[0];
    return {
      geo: {
        ['@type']: 'GeoCoordinates',
        address: getAddress(address),
        addressCountry: 'RU',
        latitude,
        longitude,
      },
    };
  }
};

export const getPhone = (entity) => {
  const phone = entity?.contacts?.find(contact => contact.type === 'phone');
  return phone ? phone.phone : regionContent.phone;
};

export const getEmail = (entity) => {
  const email = entity?.contacts?.find(contact => contact.type === 'email');
  return email ? email.email : regionContent.mail;
};

const getOpeningHoursSpecification = (entity) => {
  if (entity && entity?.schedule) {
    const result = [];
    Object.keys(entity.schedule).forEach((key) => {
      if (entity.schedule[key].length) {
        result.push({
          ['@type']: 'OpeningHoursSpecification',
          dayOfWeek: daysOfWeek[key],
          opens: `${workingTimeToString(entity.schedule[key][0].from)}${moment.tz('Europe/Moscow').format('Z')}`,
          closes: `${workingTimeToString(entity.schedule[key][0].to)}${moment.tz('Europe/Moscow').format('Z')}`,
        });
      }
    });

    return {
      openingHoursSpecification: result,
    };
  }
};

const getDataEvent = (entity, type, baseUrl) => ({
  ...getGeneralParams(entity, type, baseUrl),
  typicalAgeRange: `${entity?.ageRestriction}`?.includes('+') ? `${entity?.ageRestriction}`
    : `${entity?.ageRestriction}+`,
  performer: {
    ['@type']: 'Organization',
    name: entity?.organization?.name,
  },
  organizer: {
    ['@type']: 'Organization',
    name: entity?.organization?.name,
  },
  eventAttendanceMode: entity?.tags?.find(tag => tag.sysName === 'onlain') ?
    'http://schema.org/OnlineEventAttendanceMode'
    : 'http://schema.org/OfflineEventAttendanceMode',
  inLanguage: 'ru',
  startDate: entity?.start ? new Date(entity.start).toISOString()
    : entity?.actualSeances?.nearest?.start ? new Date(entity.actualSeances?.nearest?.start).toISOString() : '',
  endDate: entity?.end ? new Date(entity.end).toISOString()
    : entity.actualSeances?.nearest?.end ? new Date(entity.actualSeances?.nearest?.end).toISOString() : '',
  eventStatus: 'EventScheduled',
  location: {
    ['@type']: 'Place',
    address: {
      ['@type']: 'PostalAddress',
      name: getAddress(entity?.actualSeances?.nearest?.venue?.place?.address),
      addressLocality: getLocality(entity?.actualSeances?.nearest?.venue?.place?.address),
      streetAddress: getStreetAddress(entity?.actualSeances?.nearest?.venue?.place?.address),
    },
    ...getGeo(entity?.actualSeances?.nearest?.venue?.place?.mapPosition,
      entity?.actualSeances?.nearest?.venue?.place?.address),
    name: entity?.actualSeances?.nearest?.venue?.place?.name,
  },
  offers: {
    ['@type']: 'Offer',
    availability: Boolean(entity?.actualSeances?.nearest?.ticketsInfo?.unlimited) ||
      Boolean(entity?.actualSeances?.nearest?.ticketsInfo?.available) ? 'http://schema.org/InStock'
      : 'http://schema.org/OutOfStock',
    price: `${entity?.actualSeances?.nearest?.ticketsInfo?.minPrice / 100 || 0}`,
    priceCurrency: 'RUB',
  },
});

const getDataArticle = (entity, type, baseUrl: string) => ({
  ...getGeneralParams(entity, type, baseUrl),
  alternateName: entity?.sysName,
  text: entity?.content?.find(i => i.type === 'text')?.text || '',
  about: {
    ['@type']: 'Thing',
    name: entity?.categoty?.name,
  },
  articleSection: entity?.categoty?.name,
  datePublished: new Date(entity?.publishedDate),
  articleBody: entity?.content?.find(i => i.type === 'text')?.text,
  headline: 'Соловьиный край.',
  dateModified: new Date(entity?.updateDate),
  publisher: {
    ['@type']: 'Organization',
    name: entity?.organization?.name,
    url: `${baseUrl}`,
    logo: {
      ['@type']: 'ImageObject',
      url: `${baseUrl}/images/logo.svg`,
    },
    author: {
      ['@type']: 'Organization',
      name: entity?.organization?.name,
    }
  },
  author: {
    name: entity?.organization?.name,
    url: `${baseUrl}`,
  }
});

const getDataPlace = (entity, type, baseUrl) => ({
  ...getGeneralParams(entity, type, baseUrl),
  address: {
    ['@type']: 'PostalAddress',
    name: getAddress(entity?.address),
    addressCountry: 'RU',
    addressLocality: getLocality(entity?.address),
    addressRegion: getRegion(entity?.address),
    streetAddress: getStreetAddress(entity?.address),
  },
  ...getGeo(entity?.mapPosition, entity?.address),
  ...getOpeningHoursSpecification(entity),
  latitude: getGeo(entity?.mapPosition, entity?.address).geo.latitude,
  longitude: getGeo(entity?.mapPosition, entity?.address).geo.longitude,
  telephone: getPhone(entity),
});

const getDataHotel = (entity, type, baseUrl) => ({
  ...getGeneralParams(entity, type, baseUrl),
  telephone: getPhone(entity),
  email: getEmail(entity),
  address: getAddress(entity?.address),
  priceRange: `${entity?.roomCost || 0}`,
})

const getDataMain = (baseUrl: string) => ({
  ['@type']: 'Organization',
  url: `${baseUrl}`,
  ['@context']: 'https://schema.org',
  name: `${regionContent.title}. ${regionContent.subTitle}`,
  logo: {
    ['@type']: 'ImageObject',
    url: `${baseUrl}/images/logo.svg`
  },
  sameAs: [
    `${regionContent.socials.ok}`,
    `${regionContent.socials.vk}`,
  ]
});

export const getData = (entity, type: string) => {
  const config = getConfig()

  switch (type) {
    case 'events':
      return getDataEvent(entity, type, config.portal.url);
    case 'articles':
      return getDataArticle(entity, type, config.portal.url);
    case 'places':
      return getDataPlace(entity, type, config.portal.url);
    case 'hotels':
      return getDataHotel(entity, type, config.portal.url);
    case 'restaurants':
      return getDataHotel(entity, type, config.portal.url);
    default:
      return getDataMain(config.portal.url);
  }
};

export type SeoButtonTicketsType = 'paidTickets' | 'freeTickets' | 'externalLink';

export const ymGoal = (goal: string, params = {}) => {
  if (!goal) return;
  const yandexId: number = regionContent.analytics.yandex;
  if (yandexId && (window as any).ym) {
    const ym = (window as any).ym;
    ym(yandexId, 'reachGoal', goal, params);
  }
};

export const ymHit = (url, params = {}) => {
  const yandexId: number = regionContent.analytics.yandex;
  if (yandexId && (window as any).ym) {
    const ym = (window as any).ym;
    ym(yandexId, 'hit', url, params);
  }
};

// Специальные типы для составной цели seo - см. https://jira-media.fabit.ru/browse/CR-2599
type SeoTicketsType = 'paidTickets' | 'freeTickets' | 'freeNoTickets' | 'externalLink';

export const getEntitySeoTicketsType = (entityType: EntityType, entity: IEvent | IPlace | IArticle): SeoTicketsType => {
  if (entityType === 'events') {
    const event = entity as IEvent;
    const all = event.actualSeances.all || [];
    const ticketsAvailable = all.some(s => s.ticketsInfo.available > 0 || s.ticketsInfo.unlimited);
    const thereIsFreeTickets = all.some(s => (s.ticketsInfo.available > 0 || s.ticketsInfo.unlimited)
      && s.ticketsInfo.minPrice === 0 && s.ticketsInfo.maxPrice === 0);
    const thereIsPaidTickets = all.some(s => (s.ticketsInfo.available > 0 || s.ticketsInfo.unlimited)
      && s.ticketsInfo.minPrice > 0);

    if (ticketsAvailable && thereIsFreeTickets && !thereIsPaidTickets) {
      return 'freeTickets';
    }
    if (ticketsAvailable && thereIsPaidTickets) {
      return 'paidTickets';
    }
    if (event.actualExternalSaleLink || event.placesSaleLinks) return 'externalLink';
    if (!ticketsAvailable && (entity as IEvent).isFree) return 'freeNoTickets';
  }

  if (entityType === 'places' || entityType === 'overviews') {
    const place = entity as IPlace;
    if (place.ticketsInfo.minPrice === 0 && (place.ticketsInfo.available > 0 || place.ticketsInfo.unlimited)) {
      return 'freeTickets';
    }
    if (place.ticketsInfo.available > 0 || place.ticketsInfo.unlimited) {
      return 'paidTickets';
    }
  }

  return null;
}

export const seoShowButtonHandler = ({ entityType, entity, }: {
  entityType: EntityType;
  entity: IEvent | IPlace | IArticle;
}) => {
  const ticketsType = getEntitySeoTicketsType(entityType, entity);
  switch (ticketsType) {
    case 'paidTickets':
      ymGoal(regionContent.analytics?.goals?.paidVisit, { entity_type: entityType, number: entity._id });
      break;
    case 'freeTickets':
      ymGoal(regionContent.analytics?.goals?.freeVisit, { entity_type: entityType, number: entity._id });
      break;
    case 'externalLink':
      ymGoal(regionContent.analytics?.goals?.externalLinkVisit, {
        entity_type: entityType,
        number: entity._id
      });
      break;
    case 'freeNoTickets':
      ymGoal(regionContent.analytics?.goals?.freeNoTicketsVisit, {
        entity_type: entityType,
        number: entity._id
      });
      break;
  }
}

export const seoBuyButtonClickHandler = ({
  entityType,
  entity,
  position
}: {
  entityType: EntityType;
  entity: IEvent | IPlace | IArticle;
  position: string;
}) => {
  ymGoal(regionContent.analytics?.goals?.ticketsAdd, { entity_type: entityType, number: entity._id, position });

  const ticketsType = getEntitySeoTicketsType(entityType, entity);
  switch (ticketsType) {
    case 'paidTickets':
      ymGoal(regionContent.analytics?.goals?.paidBuy, { entity_type: entityType, number: entity._id });
      break;
    case 'freeTickets':
      ymGoal(regionContent.analytics?.goals?.freeBuy, { entity_type: entityType, number: entity._id });
      break;
    case 'externalLink':
      ymGoal(regionContent.analytics?.goals?.externalLinkBuy, { entity_type: entityType, number: entity._id });
      break;
  }
};

// Отправка яндекс цели при регистрации
export const seoSendRegistrationGoal = () => {
  if (regionContent.analytics?.goals?.registration) {
    ymGoal(regionContent.analytics.goals.registration);
  }
}

type Containers = { locales: ILocale[], places: IPlace[], tags: ITag[], genres: ITag[] };
export type FiltersSeo = { title: string, description: string, h1: string };

export function getFiltersSeo(
  newFilters: QueryFilters,
  page: Page,
  seoPages: any,
  containers: Containers
): FiltersSeo {
  const builder = new SeoBuilder();
  // Важен порядок добавления фильтров в билдер, т.к. он закреплен в контенте (regions/kursk/content)
  if (filtersHas(FilterTypes.LOCALE, newFilters)) {
    const locale = containers?.locales?.find(({ sysName }) => sysName === filtersGet(FilterTypes.LOCALE, newFilters));
    if (locale) {
      builder.addFilter(FilterTypes.LOCALE, { localeName: locale.name });
    }
  }

  if (filtersHas(FilterTypes.PLACE, newFilters)) {
    const place = containers?.places?.find(({ sysName }) => sysName === filtersGet(FilterTypes.PLACE, newFilters));
    if (place) {
      builder.addFilter(FilterTypes.PLACE, { placeName: place.name });
    }
  }

  if (filtersHas(FilterTypes.DATE, newFilters)) {
    const { start } = getDateByWordForm(filtersGet(FilterTypes.DATE, newFilters));
    const formattedDate = moment(start).format('D MMMM YYYY');
    const date = moment(start).format('D MMMM');

    builder.addFilter(FilterTypes.DATE, { formattedDate, date });
  }

  if (filtersHas(FilterTypes.INTERIM, newFilters)) {
    const interim = filtersGet(FilterTypes.INTERIM, newFilters);
    const date = getStartEndByInterim(interim as Interim);
    const formattedYear = moment(date.start).format('YYYY');
    let formattedDate: string;
    if (date.start && date.end) {
      formattedDate = moment(date.start).format('D') + '-' + moment(date.end).format('D MMMM');
    } else {
      formattedDate = moment(date.start).format('D MMMM');
    }

    builder.addFilter(FilterTypes.INTERIM, {
      interim: ruInterims[interim],
      formattedDate,
      formattedYear,
    })
  }

  if (filtersHas(FilterTypes.GENRE, newFilters)) {
    const genreSysName = filtersGet(FilterTypes.GENRE, newFilters);
    const genre = containers?.genres?.find((genre) => genre.sysName === genreSysName);

    builder.addFilter(FilterTypes.GENRE, { genreName: genre.name });
  }

  if (filtersHas(FilterTypes.TAG, newFilters)) {
    const tagSysName = filtersGet(FilterTypes.TAG, newFilters);
    const tag = containers?.tags?.find((tag) => tag.sysName === tagSysName);

    builder.addFilter(FilterTypes.TAG, { tagName: tag.name });
  }

  builder.addPage(page);

  if (filtersHas(FilterTypes.TAG, newFilters)) {
    builder.addPostfix(filtersGet(FilterTypes.TAG, newFilters));
  }

  let seoData = builder.get();
  let dest = get(seoPages.filters, seoData.path);

  if (!dest && filtersHas(FilterTypes.TAG, newFilters)) {
    builder.removePage();
    builder.addPostfix('default');
    seoData = builder.get();
    dest = get(seoPages.filters, seoData.path);
  }

  return {
    title: dest?.title(seoData.args),
    description: dest?.description(seoData.args),
    h1: dest?.h1(seoData.args)
  }
}
