import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { IFAQ } from "../types/faqs";

interface IState {
  faqs: IFAQ[];
}

export const loadFAQ = createAsyncThunk<any, any, { state: IState }>('FAQ/loadFAQ',
  async () => {
    const response = await axios.get(api.faq.getFAQ());
    return {
      faqs: response.data.faqs
    };
  }
);

export const faqSlice = createSlice<IState, any>({
  name: 'FAQ',
  initialState: {
    faqs: []
  },
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadFAQ.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});

export const { setActiveMobileTab } = faqSlice.actions;
