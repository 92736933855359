/* eslint-disable max-len */
import { envVal } from '@root/config/config';
import moment from 'moment';
import { getMovieDescription, getStreamPlaceOrEvent } from '../contentUtils';
import { capitalize } from 'lodash';

const year = new Date().getFullYear();

const tagPageDefaultSeo = {
  title: ({ tagName }) => `${capitalize(tagName)} во Владивостоке ${year}. Культурное Приморье`,
  description: ({ tagName }) => `${capitalize(tagName)} во Владивостоке на ${year}. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье`,
  titleOg: () => '',
  h1: ({ tagName }) => tagName
};
const tagPageDefaultSeoEvents = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} во Владивостоке ${year}. Культурное Приморье`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} во Владивостоке на ${year}. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье`,
  titleOg: () => '',
};
const tagPageDefaultSeoPlaces = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} Владивостока. Культурное Приморье`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} во Владивостоке. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Культурное Приморье`,
  titleOg: () => '',
};

export const primorskyContent = {
  timezone: 'Asia/Vladivostok',
  yandexMapState: {
    center: [45.20, 134.40],
    zoom: 9,
  },
  locale: { _id: 230 },
  title: 'Культурное Приморье',
  subTitle: 'Культурно-туристический портал Приморского Края',
  mainBanner: {
    hide: true,
  },
  useBvi: false,
  phone: '8 800 302-52-95',
  mail: 'support@primcult.ru',
  agreementLink: '/public_offer',
  agreementFilePath: '/docs/public_offer_25_10_2022.pdf',
  socials: {
    ok: 'https://ok.ru/group/70000000796512',
    vk: 'https://vk.com/primcult',
    yaDzen: 'https://dzen.ru/primcult',
    telegram: 'https://t.me/primcult',
  },
  socialTitles: {
    vk: '«Культурное Приморье»\nво «вконтакте»',
    telegram: '«Культурное Приморье»\nв Telegram',
  },
  subscribeForm: {
    title: 'Узнавайте новости культуры первыми',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Пушкинская карта', to: '/events/tag-pushkinskaya-karta' },
          { title: 'Концерты и шоу', to: '/events/tag-koncerty-i-shou' },
          { title: 'Спектакли', to: '/events/tag-spektakli' },
          { title: 'Выставки', to: '/events/tag-vystavki' },
          { title: 'Мастер-классы', to: '/events/tag-master-klassy' },
          { title: 'Кино', to: '/movies' },
          { title: 'Фестивали', to: '/events/tag-festivali' },
          { title: 'Праздники', to: '/events/tag-prazdniki' }
        ]
      },
      { to: '/places', title: 'Места' },
      { to: '/touristroutes', title: 'Маршруты' },
      { to: '/overviews', title: 'Обзоры' },
      { title: 'Спецпроекты', linksSource: 'sprojects' },
      {
        title: 'Live',
        links: [
          { to: '/streams', title: 'Трансляции' },
          { to: '/artefacts', title: 'Дополненная реальность' },
          { to: '/museums', title: 'Виртуальные музеи' },
        ],
      },
    ],
  },
  footer: {
    title: 'Культурное Приморье.',
    logoPath: '/images/logo_footer.svg',
    systemName: `© ${year}. Культурное Приморье. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      {
        to: '/events',
        title: 'Афиша'
      },
      {
        to: '/ticketsbundles',
        title: 'Абонементы'
      },
      {
        to: '/places',
        title: 'Места'
      },
      {
        to: '/touristroutes',
        title: 'Маршруты',
      },
      {
        to: '/overviews',
        title: 'Обзоры'
      },
    ],
    callTime: 'В будние дни с 9:00 до 18:00'
  },
  analytics: {
    yandex: envVal({ production: '90979499' }, null),
    gtm: envVal({ production: 'GTM-5TWQH9Z' }, null),
    pixel: envVal({ production: '26844' }, null),
    goals: {
      paidVisit: 'events_visit_prim',
      paidBuy: 'buy_ticket_prim',
      freeVisit: 'events_visits_prim',
      freeNoTicketsVisit: 'noactive_free_prim',
      freeBuy: 'freeandzero_prim',
      externalLinkVisit: 'events_visit_redirect_prim',
      externalLinkBuy: 'buy_redirect_prim',
      ticketsAdd: 'entity_ticket_add',
      registration: 'kolvo_registration',
    }
  },
  unisenderNewsPopup: {
    id: envVal({ staging: '' }, null),
  },
  componentsConfig: {
    header: 'default',
    footer: 'default',
    heroBlock: 'banner',
    socialCardIconVk: 'default',
    socialCardIconTg: 'default',
  },
  seo: {
    pages: {
      index: {
        title: () => 'Культурное Приморье - Культурный портал Приморского Края',
        description: () => 'Культурное Приморье - Культурный портал Приморского Края. Мероприятия, места, обзоры, маршруты, трансляции',
        titleOg: () => '',
      },
      main: { title: 'Культурное Приморье - Культурный портал Приморского Края' },
      events: {
        title: () => `Афиша Владивостока ${year}. Куда сходить во Владивостоке развлечься. Купить билеты на мероприятия во Владивостоке. Культурное Приморье`,
        description: () => 'Афиша мероприятий Владивостока. Куда можно сходить во Владивостоке отдохнуть. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье',
        titleOg: () => '',
      },
      'pushkinskaya-karta/events': {
        title: () => `Пушкинская карта во Владивостоке ${year}. Купить билеты по Пушкинской карте во Владивостоке. Культурное Приморье`,
        description: () => 'Афиша мероприятий по Пушкинской карте. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия. Официальный сайт Культурное Приморье',
      },
      'koncerty-i-shou/events': {
        title: () => `Концерты во Владивостоке ${year}. Афиша концертов во Владивостоке. Культурное Приморье`,
        description: () => 'Расписания концертов во Владивостоке. Купить билеты на концерты во Владивостоке. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье',
      },
      'spektakli/events': {
        title: () => `Спектакли во Владивостоке ${year}. Купить билеты на спектакль во Владивостоке. Культурное Приморье`,
        description: () => 'Афиша спектаклей во Владивостоке. Купить билеты на спектакль во Владивостоке. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье',
      },
      'detyam/events': {
        title: () => `Детская афиша во Владивостоке ${year}. Куда пойти с ребенком во Владивостоке. Культурное Приморье`,
        description: () => 'Детские мероприятия во Владивостоке. Куда пойти с ребенком во Владивостоке. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье',
      },
      'tagPageDefaultSeoevents': tagPageDefaultSeoEvents,
      places: {
        title: () => `Все интересные места и достопримечательности Владивостока ${year}. Приморский Край`,
        description: () => `Интересные и необычные достопримечательности во Владивостоке ${year} — описания, цены на билеты, адреса, режим работы, фотографии, адреса на карте. Приморский Край`,
        titleOg: () => '',
      },
      'obuchenie/places': {
        title: () => `Образовательные учреждения и места Владивостока. Культурное Приморье`,
        description: () => `Образовательные учреждения и места во Владивостоке. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Культурное Приморье`,
        titleOg: () => '',
      },
      'detyam/places': {
        title: () => `Детские места Владивостока. Культурное Приморье`,
        description: () => `Места для детей во Владивостоке. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Культурное Приморье`,
        titleOg: () => '',
      },
      'prochie/places': {
        title: () => `Красивые места Владивостока. Культурное Приморье`,
        description: () => `Красивые места во Владивостоке. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Культурное Приморье`,
        titleOg: () => '',
      },
      'na-vozdukhe/places': {
        title: () => `Необычные места Владивостока. Культурное Приморье`,
        description: () => `Необычные места во Владивостоке. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Культурное Приморье`,
        titleOg: () => '',
      },
      'sport/places': {
        title: () => `Спортивные объекты и места Владивостока. Культурное Приморье`,
        description: () => `Спортивные объекты и места во Владивостоке. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Культурное Приморье`,
        titleOg: () => '',
      },
      'muzyka/places': {
        title: () => `Музыкальные места Владивостока. Культурное Приморье`,
        description: () => `Музыкальные места во Владивостоке. Подробная информация о местах: описания, адреса, телефоны, сайты, фото, расписание. Официальный сайт Культурное Приморье`,
        titleOg: () => '',
      },
      'tagPageDefaultSeoplaces': tagPageDefaultSeoPlaces,
      'tagPageDefaultSeo': tagPageDefaultSeo,
      routes: {
        title: () => `Туристические маршруты во Владивостоке ${year}. Приморский Край`,
        description: () => 'Лучшие туристические маршруты, которые можно исходить пешком или проехать на автомобиле. Карты туристических маршрутов, описание, необычные идеи для путешествия во Владивостоке. Приморский Край',
        titleOg: () => '',
      },
      overviews: {
        title: () => `Обзоры ${year} — Владивосток. Приморский Край`,
        description: () => `Обзоры лучших событий, мест ${year} — Владивосток. Приморский Край`,
        titleOg: () => '',
      },
      hotels: {
        title: () => `Гостиницы во Владивостоке ${year}. Приморский Край`,
        description: () => 'Гостиницы во Владивостоке. Подробная информация: описания, адрес, режимы работы, фото, адреса на карте, рейтинги. Приморский Край',
        titleOg: () => '',
      },
      restaurants: {
        title: () => `Рестораны во Владивостоке ${year}. Приморский Край`,
        description: () => 'Рестораны во Владивостоке. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Приморский Край',
        titleOg: () => '',
      },
      streams: {
        title: () => `Трансляции - Приморский край ${year}. Культурное Приморье`,
        description: () => `Трансляции - Приморский край ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Культурное Приморье`,
        titleOg: () => '',
        h1: () => `Трансляции - Приморский край ${year}`,
      },
      ticketsBundles: {
        title: () => 'Абонементы. Приморский Край',
        description: () => 'Абонементы в Приморском Крае',
        titleOg: () => '',
      },
      movies: {
        title: () => `Киноафиша во Владивостоке | Афиша мероприятий с точными датами на ${year} год - Культурное Приморье`,
        description: () => `Киноафиша во Владивостоке на ${year} год ✅ Афиша интересных мероприятий в Приморском крае. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Культурное Приморье`,
        titleOg: () => '',
      },
      singleEvent: {
        title: ({ entity }) => `${entity.name} — описание, программа мероприятия, дата, время. Адрес места проведения — ${entity.address}. Афиша`,
        description: ({ entity }) => `${entity.name}. ✅ Место проведения — ${entity.placeName}, ✅ дата проведения — ${entity.eventDate ? moment(entity.eventDate).format('D MMMM YYYY') : ''}, ✅ адрес места проведения — ${entity.address}. Подробная информация: описание, фото. Официальный сайт — Приморского Края`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singlePlace: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}. Фото, режим работы, афиша и события. Приморский Край`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все туристические места и достопримечательности на сайте — Приморского Края`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleRoute: {
        title: ({ entity }) => `${entity.name}. Подробная информация: описание, адреса мест на карте, фото. Приморский Край`,
        description: ({ entity }) => `${entity.name}. Лучшие туристические маршруты, которые можно исходить пешком или проехать на автомобиле. Приморский Край`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleOverview: {
        title: ({ entity }) => `${entity.name}. Приморский Край`,
        description: ({ entity }) => `Обзор - ${entity.name}. Официальный сайт Приморского Края`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleHotel: {
        title: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address}. Фото, режим работы. Приморский Край`,
        description: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все гостиницы на сайте — Приморского Края`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleRestaurant: {
        title: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address}. Фото, режим работы. Приморский Край`,
        description: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все рестораны на сайте — Приморского Края`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleStream: {
        title: ({ entity }) => `Трансляция ${entity.name}. ${getStreamPlaceOrEvent(entity)}Прямая трансляция на сайте Культурное Приморье`,
        description: ({ entity }) => `${entity.name}. ${getStreamPlaceOrEvent(entity)}Дата и время проведения — ${entity.liveDate ? moment(entity.liveDate).format('D MMMM YYYY H:mm') : ''} на сайте Культурное Приморье`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleTicketsBundle: {
        title: ({ entity }) => `Пакет билетов ${entity?.name}. Приморский Край`,
        description: ({ entity }) => `Пакет билетов ${entity?.name}. Купить билеты в Приморском Крае`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleMovie: {
        title: ({ entity }) => `${entity?.name} - купить билеты в кино. Расписания киносеансов в Приморском Крае`,
        description: ({ entity }) => `${entity?.name} - афиша сеансов в Приморском Крае, продажа билетов по Пушкинской карте! Описание фильма:` +
          `${getMovieDescription(entity)}` +
          '. Официальный сайт Культурное Приморье',
        titleOg: ({ entity }) => `${entity.name}`
      },
      museums: {
        title: () => `Культурное Приморье - Культурный портал Приморского Края`,
        description: () => 'Культурное Приморье - Культурный портал Приморского Края. Мероприятия, места, обзоры, маршруты, трансляции',
      },
      artefacts: {
        title: () => `Культурное Приморье - Культурный портал Приморского Края`,
        description: () => 'Культурное Приморье - Культурный портал Приморского Края. Мероприятия, места, обзоры, маршруты, трансляции',
      },
      faq: {
        title: () => `Часто задаваемые вопросы. Культурное Приморье - Культурный портал Приморского Края`,
        description: () => 'Часто задаваемые вопросы. Культурное Приморье - Культурный портал Приморского Края. Мероприятия, места, обзоры, маршруты, трансляции',
      },
      pushkincard: {
        title: () => `Культурное Приморье - Культурный портал Приморского Края`,
        description: () => 'Культурное Приморье - Культурный портал Приморского Края. Мероприятия, места, обзоры, маршруты, трансляции',
        titleOg: () => ``,
      },
      // Порядок вложенности необходимо соблюдать, иначе функция получения сео метаданных отработает неправильно
      // В единсвенном числе - фильтр (locale, place, date, ...), с приставкой Page(eventsPage, hotelsPage, ...) - страница, для которой метаданные
      // Примеры:
      // locale->date->eventsPage: Фильтр район + фильтр дата для страницы событий
      // place->tag->eventsPage->default: Фильтр место + тэг для страницы событий
      // tag->eventsPage->pushkinskaya-karta: Тэг Пушкцинская карта для страницы событий
      filters: {
        locale: {
          eventsPage: {
            title: ({ localeName }) => `${localeName} - афиша ${year}. Интересные развлекательные события. Культурное Приморье`,
            description: ({ localeName }) => `${localeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
            h1: ({ localeName }) => `${localeName} - афиша`,
          },
          placesPage: {
            title: ({ localeName }) => `Интересные места - ${localeName} ${year}. Культурное Приморье`,
            description: ({ localeName }) => `Все интересные места и достопримечательности - ${localeName} ${year} — описания, цены на билеты, режим работы, фотографии, адреса на карте. Официальный сайт Культурное Приморье`,
            h1: ({ localeName }) => `Места - ${localeName}`
          },
          restaurantsPage: {
            title: ({ localeName }) => `Кафе и рестораны - ${localeName} ${year}. Культурное Приморье`,
            description: ({ localeName }) => `Кафе и рестораны - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт Культурное Приморье`,
            h1: ({ localeName }) => `Кафе и рестораны - ${localeName}`
          },
          hotelsPage: {
            title: ({ localeName }) => `Гостиницы и отели - ${localeName} ${year}. Культурное Приморье`,
            description: ({ localeName }) => `Гостиницы и отели - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт Культурное Приморье`,
            h1: ({ localeName }) => `Гостиницы и отели - ${localeName}`
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}. Интересные развлекательные события. Культурное Приморье`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье`,
                h1: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}`,
              }
            },
            placesPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Культурное Приморье`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Культурное Приморье`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            hotelsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Культурное Приморье`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Культурное Приморье`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            restaurantsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Культурное Приморье`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Культурное Приморье`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            }
          },
          place: {
            eventsPage: {
              title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Культурное Приморье`,
              description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
              h1: ({ placeName }) => `${placeName} - афиша`,
            }
          },
          date: {
            eventsPage: {
              title: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Культурное Приморье`,
              description: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные мероприятия в Приморском крае. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
              h1: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date}`,
            }
          },
          interim: {
            eventsPage: {
              title: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Культурное Приморье`,
              description: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
              h1: ({ localeName, interim, formattedDate }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate}`,
            }
          }
        },
        place: {
          eventsPage: {
            title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Культурное Приморье`,
            description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
            h1: ({ placeName }) => `${placeName} - афиша`,
          },
          streamsPage: {
            title: ({ placeName }) => `Трансляции - ${placeName} ${year}. Культурное Приморье`,
            description: ({ placeName }) => `Трансляции - ${placeName} ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Культурное Приморье`,
            h1: ({ placeName }) => `Трансляции - ${placeName}`
          },
          date: {
            eventsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Культурное Приморье`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные мероприятия в Приморском крае. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date}`
            },
            streamsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные развлекательные трансляции. Культурное Приморье`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные трансляции - Приморский край. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date}`
            }
          },
          interim: {
            eventsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Культурное Приморье`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate}`,
            },
            streamsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные трансляции. Культурное Приморье`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные трансляции - Приморский край. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate}`,
            }
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Интересные развлекательные события. Культурное Приморье`,
                description: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье`,
                h1: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}`,
              }
            }
          }
        },
        date: {
          eventsPage: {
            title: ({ formattedDate }) => `Афиша Владивостока на ${formattedDate} года. Интересные развлекательные события во Владивостоке. Культурное Приморье`,
            description: ({ formattedDate }) => `Афиша во Владивостоке на ${formattedDate} года. Интересные мероприятия Владивостока. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
            h1: ({ date }) => `Афиша на ${date}`,
          },
          moviesPage: {
            title: ({ formattedDate }) => `Киноафиша во Владивостоке на ${formattedDate}. Кино во Владивостоке - ${formattedDate}. Культурное Приморье`,
            description: ({ formattedDate }) => `Кино во Владивостоке - ${formattedDate} ✅ Киноафиша во Владивостоке на ${formattedDate}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
            h1: ({ date }) => `Киноафиша на ${date}`,
          },
          streamsPage: {
            title: ({ formattedDate }) => `Трансляции ${formattedDate} года. Культурное Приморье`,
            description: ({ formattedDate }) => `Трансляции - ${formattedDate} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Культурное Приморье`,
            h1: ({ date }) => `Трансляции - ${date}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные развлекательные события. Культурное Приморье`,
                description: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные мероприятия - Приморский край. Подробная информация о мероприятиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
                h1: ({ tagName, date }) => `${capitalize(tagName)} - афиша на ${date}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, formattedDate }) => `Киноафиша в жанре ${genreName} на ${formattedDate} года - Приморский край. Культурное Приморье`,
              description: ({ genreName, formattedDate }) => `Кино в жанре ${genreName} на ${formattedDate} года - Приморский край. Киноафиша на ${formattedDate} года - Приморский край. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
              h1: ({ genreName, date }) => `Киноафиша в жанре ${genreName} на ${date}`,
            }
          }
        },
        interim: {
          eventsPage: {
            title: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate} во Владивостоке. Интересные развлекательные события во Владивостоке. Культурное Приморье`,
            description: ({ interim, formattedDate }) => `Афиша Владивостока на ${interim}, ${formattedDate}. Интересные мероприятия Владивостока. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
            h1: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate}`,
          },
          moviesPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Киноафиша на ${interim}, ${formattedDate} ${formattedYear} года во Владивостоке. Кино во Владивостоке ${interim}, на ${formattedDate} ${formattedYear} года. Культурное Приморье`,
            description: ({ interim, formattedDate, formattedYear }) => `Кино во Владивостоке на ${interim}, ${formattedDate} ${formattedYear} года ✅ Киноафиша во Владивостоке ${interim}, ${formattedDate} ${formattedYear} года. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
            h1: ({ interim, formattedDate }) => `Киноафиша на ${interim}, ${formattedDate}`,
          },
          streamsPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Культурное Приморье`,
            description: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Культурное Приморье`,
            h1: ({ interim, formattedDate }) => `Трансляции на ${interim}, ${formattedDate}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные развлекательные события. Культурное Приморье`,
                description: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
                h1: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Приморский край. Культурное Приморье`,
              description: ({ genreName, interim, formattedDate }) => `Кино в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Приморский край. Киноафиша на ${interim} ${formattedDate} ${year} года - Приморский край. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
              h1: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate}`,
            }
          }
        },
        tag: {
          eventsPage: {
            'pushkinskaya-karta': {
              title: () => `Пушкинская карта ${year} во Владивостоке. Купить билеты по Пушкинской карте во Владивостоке. Культурное Приморье`,
              description: () => `Афиша мероприятий по Пушкинской карте Владивостока. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия. Официальный сайт Культурное Приморье`,
              h1: () => `Пушкинская карта`
            },
            'koncerty-i-shou': {
              title: () => `Концерты во Владивостоке ${year}. Афиша концертов Владивостока. Культурное Приморье`,
              description: () => `Расписания концертов во Владивостоке. Купить билеты на концерты во Владивостоке. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье`,
              h1: () => `Концерты и шоу`
            },
            'spektakli': {
              title: () => `Спектакли во Владивостоке ${year}. Купить билеты на спектакль во Владивостоке. Культурное Приморье`,
              description: () => `Афиша спектаклей во Владивостоке. Купить билеты на спектакль во Владивостоке. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье`,
              h1: () => `Спектакли`
            },
            'detyam': {
              title: () => `Детская афиша во Владивостоке ${year}. Куда пойти с ребенком во Владивостоке. Культурное Приморье`,
              description: () => `Детские мероприятия во Владивостоке. Куда пойти с ребенком во Владивостоке. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурное Приморье`,
              h1: () => `Детям`
            },
            default: tagPageDefaultSeo
          },
          streamsPage: {
            default: {
              title: ({ tagName }) => `Трансляции - ${tagName}. Культурное Приморье`,
              description: ({ tagName }) => `Трансляции - ${tagName}. Прямые трансляции: смотреть онлайн и бесплатно Официальный сайт Культурное Приморье`,
              h1: ({ tagName }) => `Трансляции - ${tagName}`,
            }
          },
          restaurantsPage: {
            default: {
              title: ({ tagName }) => `${tagName} Владивостока ${year}. Культурное Приморье`,
              description: ({ tagName }) => `${tagName} во Владивостоке. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт Культурное Приморье`,
              h1: ({ tagName }) => tagName,
            }
          },
          hotelsPage: {
            default: {
              title: ({ tagName }) => `Гостиницы и отели - ${tagName} ${year}. Культурное Приморье`,
              description: ({ tagName }) => `Гостиницы и отели - ${tagName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт Культурное Приморье`,
              h1: ({ tagName }) => `Гостиницы и отели - ${tagName}`,
            }
          }
        },
        genre: {
          moviesPage: {
            title: ({ genreName }) => `Киноафиша во Владивостоке, жанр - ${genreName}. Кино во Владивостоке, жанр - ${genreName}. Культурное Приморье`,
            description: ({ genreName }) => `Кино во Владивостоке, жанр - ${genreName} ✅ Киноафиша во Владивостоке, жанр - ${genreName}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурное Приморье`,
            h1: ({ genreName }) => `Киноафиша, жанр - ${genreName}`
          },
        }
      },
    },
  },
  homePageBlocks: [
    'best',
    'events',
    'subscribe',
    'places',
    'overviews',
    'routes',
    'socials',
    'restaurants',
    'hotels',
    'ticketsBundles'
  ]
};
