import { notificationSlice } from "./notification.slice";
import { picksSlice } from "./picks";
import { loadingSlice } from "./loading.slice";
import { eventSlice } from './event.slice';
import { articlesSlice } from './articles.slice';
import { placesListSlice } from './places.slice';
import { hotelsListSlice } from './hotels.slice';
import { recommendationSlice } from './recommendation.slice';
import { restaurantsListSlice } from './restaurants.slice';
import { subscriptionFormSlice } from "./subscriptionForm.slice";
import { writeToUsFormSlice } from "./writeToUsForm.slice";
import { streamSlice } from "./streams.slice";
import { movieSlice } from "./movies.slice";
import {
  eventsSlice,
  hotelsSlice,
  moviesSlice,
  overviewsSlice,
  newsSlice,
  placesSlice,
  restaurantsSlice,
  routesSlice,
  streamsSlice,
  ticketsBundlesSlice,
  touristsRoutesSlice,
  excursionsSlice,
  toursSlice,
  goodsSlice
} from "./entities.slice";
import { localesSlice } from "./locales.slice";
import { ticketsBundleSlice } from "./ticketsbundles.slice";
import { getPriceSlice } from "./minAndMaxPrices.slice";
import { faqSlice } from "./faq.slice";
import { authSlice } from "./auth.slice";
import { searchSlice } from "./search.slice";
import { invoicesSlice } from "./invoices.slice";
import { touristRoutesSlice } from "./touristroutes.slice";
import { streamsBannersSlice } from "./streamsBanners.slice";
import { sprojectsSlice } from "./sprojects.slice";
import { feedbacksSlice } from "./feedbacks.slice";
import { eventsPlacesSlice } from "./eventsPlaces.slice";
import { streamsPlacesSlice } from "./streamsPlaces.slice";
import { widgetWeatherSlice } from "./widgetWeather.slice";
import { districtsSlice } from "./districts.slice";
import { excursionSlice } from "./excursion.slice";
import { tourSlice } from "./tour.slice";
import { bookingSlice } from "./booking.slice";
import { favoritesSlice } from "./favorites/favorites.slice";

export const slices = [
  notificationSlice,
  picksSlice,
  loadingSlice,
  eventSlice,
  placesListSlice,
  hotelsListSlice,
  articlesSlice,
  recommendationSlice,
  restaurantsListSlice,
  subscriptionFormSlice,
  writeToUsFormSlice,
  localesSlice,
  streamSlice,
  ticketsBundleSlice,
  eventsSlice,
  faqSlice,
  placesSlice,
  authSlice,
  hotelsSlice,
  restaurantsSlice,
  overviewsSlice,
  newsSlice,
  searchSlice,
  streamsSlice,
  routesSlice,
  streamsBannersSlice,
  invoicesSlice,
  touristRoutesSlice,
  ticketsBundlesSlice,
  touristsRoutesSlice,
  getPriceSlice,
  sprojectsSlice,
  moviesSlice,
  movieSlice,
  feedbacksSlice,
  eventsPlacesSlice,
  streamsPlacesSlice,
  widgetWeatherSlice,
  districtsSlice,
  excursionsSlice,
  excursionSlice,
  toursSlice,
  tourSlice,
  bookingSlice,
  goodsSlice,
  favoritesSlice,
];
