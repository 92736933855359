import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api, paramsSerializer, paramsToQuery } from "../../urls";
import { IPlace } from "../types/places";

interface IState {
  items: IPlace[];
}

export const loadEventPlaces = createAsyncThunk<any, any>('eventsPlacesSlice/loadEventPlaces',
  async (params) => {
    const response = await axios.get(
      api.events.getEventsPlaces(),
      { params: paramsToQuery({ ...params }), paramsSerializer }
    );

    if (!response?.data?.places?.length) {
      return null;
    }

    return response?.data?.places;
  });

export const eventsPlacesSlice = createSlice<IState, any>({
  name: 'eventsPlaces',
  initialState: {
    items: [],
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loadEventPlaces.fulfilled, (state, { payload }) => {
      if (payload) {
        state.items = payload;
      }
    });
  }
});
