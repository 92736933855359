import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { ILocale } from '../types/common';
import { IEvent } from '../types/event';
import { IPlace } from '../types/places';
import { regionContent } from '../../../kursk/content/regionContent';

export interface IItemsState<T> {
  items: T;
  total: number;
  start?: number;
}

interface IState {
  locales: ILocale[];
  currentLocale: ILocale;
  events: IItemsState<IEvent[]>;
  places: IItemsState<IPlace[]>;
}

export const loadDistricts = createAsyncThunk<any, any>('districtsSlice/loadDistricts',
  async () => {
    const params = { type: 'area', limit: 100, parent: regionContent.locale._id, sort: 'name' };
    const response = await axios.get(api.locales.get(), { params });
    return {
      locales: response.data.locales
    };
  }
);

export const loadOneDistrict = createAsyncThunk<any, any>('districtsSlice/loadOneDistrict',
  async (params: any) => {
    const response = await axios.get(api.locales.getOne(params._id));
    return {
      currentLocale: response.data.locale,
    };
  }
);

export const loadDistrictEvents = createAsyncThunk<any, any, { state: IState }>('districtsSlice/loadDistrictEvents',
  async (params: any, { getState }) => {
    const state = getState()['districtsSlice'];
    const start = state.events.start || new Date().valueOf();
    const locales = params.locales || state.currentLocale._id;
    const newParams = { ...params, locales, limit: 8, sort: '-rating', start };
    const response = await axios.get(api.events.get(), { params: newParams });

    return {
      events: {
        items: response.data.events,
        total: response.data.total,
        start,
      },
    };
  }
);

export const loadDistrictPlaces = createAsyncThunk<any, any, { state: IState }>('districtsSlice/loadDistrictPlaces',
  async (params: any, { getState }) => {
    const state = getState()['districtsSlice'];
    const locales = params.locales || state.currentLocale._id;
    const newParams = { ...params, locales, limit: 8 };
    const response = await axios.get(api.places.get(), { params: newParams });

    return {
      places: {
        items: response.data.places,
        total: response.data.total,
      },
    };
  }
);

export const districtsSlice = createSlice<IState, any>({
  name: 'districtsSlice',
  initialState: {
    locales: [],
    currentLocale: null,
    events: {
      items: [],
      total: 0,
    },
    places: {
      items: [],
      total: 0,
    },
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDistricts.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(loadOneDistrict.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(loadDistrictEvents.fulfilled, (state, { payload }) => {
      if (payload) {
        state.events.items = [...state.events.items, ...payload.events.items];
        state.events.total = payload.events.total;
        state.events.start = payload.events.start;
      }
    });
    builder.addCase(loadDistrictPlaces.fulfilled, (state, { payload }) => {
      if (payload) {
        state.places.items = [...state.places.items, ...payload.places.items];
        state.places.total = payload.places.total;
      }
    });
  }
});
