import React from 'react';
import classNames from 'classnames';

import styles from './Loader.module.scss';

export const Loader = ({ alignTop = false, fixed = false }) => (
  <div className={classNames(styles.loader, { [styles.alignTop]: alignTop, [styles.fixed]: fixed })}>
    <div className={styles.backdrop}></div>
    <div className={styles.img}>
      <img src={'/images/loader.svg'} />
    </div>
  </div>
);
