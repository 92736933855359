import { createContext, useContext, useEffect, useState } from 'react';
import { mediaQueryBreakpoints, Orientations, orientations } from '../utils/constants';

export const OrientationContext = createContext<Orientations>(orientations.desktop);

export const useOrientations = (): Orientations => {
  const deviceType = useContext(OrientationContext);
  const [orientation, setOrientation] = useState(deviceType || orientations.desktop);

  const onResize = () => {
    const windowWidth = document.documentElement.clientWidth;
    if (windowWidth <= mediaQueryBreakpoints.tablet) {
      setOrientation(orientations.mobile);
    }
    if (windowWidth >= mediaQueryBreakpoints.tablet && windowWidth <= mediaQueryBreakpoints.desktop) {
      setOrientation(orientations.tablet);
    }
    if (windowWidth > mediaQueryBreakpoints.desktop) {
      setOrientation(orientations.desktop);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      onResize();
    }
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return orientation;
};
