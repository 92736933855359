/* eslint-disable max-len */
import { envVal } from '@root/config/config';

const year = new Date().getFullYear();

export const pprogContent = {
  timezone: 'Europe/Moscow',
  yandexMapState: {
    center: [56.129057, 40.406635],
    zoom: 9,
  },
  locale: { _id: 1 },
  title: 'Президентская программа подготовки управленческих кадров',
  subTitle: 'Президентская программа подготовки управленческих кадров',
  mainBanner: {
    hide: true,
    title: '',
    text: '',
    links: []
  },
  useBvi: false,
  phone: '8 800 000-00-00',
  mail: 'support@pprog.ru',
  agreementLink: '/#',
  socials: {
    ok: '',
    vk: 'https://vk.ru/pprog_russia',
    yaDzen: '',
    telegram: 'https://t.me/active_pp',
    facebook: '',
    instagram: '',
  },
  socialTitles: {
    vk: 'вконтакте',
    telegram: 'Telegram',
  },
  subscribeForm: {
    title: 'Будьте в курсе всех новостей',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Круглые столы', to: '/events/tag-kruglye-stoly' },
          { title: 'Конференции', to: '/events/tag-konferencii' },
          { title: 'Форумы', to: '/events/tag-forumy' },
          { title: 'Стажировки', to: '/events/tag-stazhirovki' },
        ]
      },
      { title: 'Новости', to: '/overviews/tag-novosti' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Выпускники', to: '/overviews/tag-vypuskniki' },
    ],
  },
  footer: {
    title: 'Президентская программа\nподготовки управленческих кадров',
    systemName: `© ${year}. Президентская программа подготовки управленческих кадров. Все права защищены.`,
    hidePushkin: true,
    links: [
      { title: 'Афиша', to: '/events' },
      { title: 'Новости', to: '/overviews/tag-novosti' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Выпускники', to: '/overviews/tag-vypuskniki' },
    ],
    callTime: 'В будние дни с 9:00 до 18:00'
  },
  analytics: {
    yandex: envVal({ production: '' }, null),
    gtm: envVal({ production: '' }, null),
    pixel: envVal({ production: '' }, null),
    goals: {
      paidVisit: 'events_visit_pprog',
      paidBuy: 'buy_ticket_pprog',
      freeVisit: 'events_visits_pprog',
      freeNoTicketsVisit: 'noactive_free_pprog',
      freeBuy: 'freeandzero_pprog',
      externalLinkVisit: 'events_visit_redirect_pprog',
      externalLinkBuy: 'buy_redirect_pprog',
      ticketsAdd: 'entity_ticket_add',
    }
  },
  unisenderNewsPopup: {
    id: envVal({ staging: '', }, null),
  },
  componentsConfig: {
    header: 'default',
    footer: 'default',
    heroBlock: 'banner',
    socialCardIconVk: 'v2',
    socialCardIconTg: 'v2',
  },
  seo: {
    pages: {
      index: {
        title: () => 'Президентская программа подготовки управленческих кадров',
        description: () => 'Президентская программа подготовки управленческих кадров',
        titleOg: () => 'Президентская программа подготовки управленческих кадров'
      },
      main: { title: 'Президентская программа подготовки управленческих кадров' },
    },
  },
  homePageBlocks: [
    'best',
    'events',
    'subscribe',
    'places',
    'overviews',
    'routes',
    'socials',
    'restaurants',
    'hotels',
    'ticketsBundles'
  ]
};
