import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { regionContent } from "../../../kursk/content/regionContent";
import { api, paramsSerializer, paramsToQuery } from "../../urls";
import { ILocale } from "../types/common";

interface IState {
  items: ILocale[];
}

export const loadLocales = createAsyncThunk<ILocale[], any>('locales/load', async () => {
  const options = {
    type: 'area',
    limit: 100,
    parent: regionContent.locale._id,
    sort: { name: 1 }
  }
  const response = await axios.get(api.locales.get(), { params: paramsToQuery({ ...options }), paramsSerializer });

  if (!response?.data?.locales?.length) {
    return null;
  }

  return response?.data?.locales;
});

export const localesSlice = createSlice<IState, any>({
  name: 'locales',
  initialState: {
    items: [],
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loadLocales.fulfilled, (state, { payload }) => {
      if (payload) {
        state.items = payload;
      }
    });
  }
});
