import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from "../../urls";
import {
  IArticlePick,
  IBannerPick,
  IBestPick,
  IEventPick,
  IHotelPick,
  IPlacePick,
  IRestaurantPick,
  ITicketsBundlesPick,
  ITouristRoute
} from '../types/picks';

export interface IBannersInCatalogsParams {
  section: string;
  tagsSysNames?: string;
}

export interface IPicksState {
  bestEvents: IEventPick[];
  best: IBestPick[];
  bestTicketsBundles: ITicketsBundlesPick[];
  mainBanners: IBannerPick[];
  bannersInCatalogs: IBannerPick[];
  bestOverviews: IArticlePick[];
  bestRoutes: ITouristRoute[];
  bestPlaces: IPlacePick[];
  bestRestaurants: IRestaurantPick[];
  bestHotels: IHotelPick[];
  topPushkinEvents: IEventPick[];
}

export const loadBestEventsThunk = createAsyncThunk<IEventPick[], any>('picks/loadBestEventsThunk', async (params) => {
  const response = await axios.get(api.picks.get(), { params: { types: 'bestEvents', ...params } });
  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadBestTicketsBundlesThunk = createAsyncThunk<ITicketsBundlesPick[], any>
('picks/loadBestTicketsBundlesThunk', async (params) => {
  const response = await axios.get(api.picks.get(), { params: { types: 'bestTicketsBundles', ...params } });
  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadBestThunk = createAsyncThunk<IEventPick[], any>('picks/loadBestThunk', async (params) => {
  const response = await axios.get(api.picks.get(), { params: { types: 'best', ...params } });
  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadTopPushkinEventsThunk = createAsyncThunk<IEventPick[], any>
('picks/loadTopPushkinEventsThunk', async (params) => {
  const response = await axios.get(api.picks.get(), { params: { types: 'topPushkinEvents', ...params } });
  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadMainBannersThunk = createAsyncThunk<IBannerPick[], any>('picks/loadMainBannersThunk', async () => {
  const response = await axios.get(api.picks.get(), {
    params: {
      types: 'mainBanner',
      complement: false,
      realm: 'culture'
    }
  })
  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadBestOverviewsThunk = createAsyncThunk<IArticlePick[], any>
('picks/loadBestOverviewsThunk', async (params) => {
  const response = await axios.get(api.picks.get(), { params: { types: 'bestOverviews', ...params } })

  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadBestRoutesThunk = createAsyncThunk<ITouristRoute[], any>
('picks/loadBestRoutesThunk', async (params) => {
  const response = await axios.get(api.picks.get(), { params: { types: 'bestTouristRoutes', ...params } })

  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadBestPlacesThunk = createAsyncThunk<IPlacePick[], any>('picks/loadBestPlacesThunk', async (params) => {
  const response = await axios.get(api.picks.get(), { params: { types: 'bestPlaces', ...params } })

  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadBestRestaurantsThunk = createAsyncThunk<IRestaurantPick[], any>
('picks/loadBestRestaurantsThunk', async (params) => {
  const response = await axios.get(api.picks.get(), { params: { types: 'bestRestaurants', ...params } })

  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadBestHotelsThunk = createAsyncThunk<IHotelPick[], any>('picks/loadBestHotelsThunk', async (params) => {
  const response = await axios.get(api.picks.get(), { params: { types: 'bestHotels', ...params } })

  if (!response?.data?.picks[0]?.items?.length) {
    return null;
  }

  return response?.data?.picks[0].items;
});

export const loadBannersInCatalogs = createAsyncThunk<IBannerPick[], any>(
  'picks/loadBannersInCatalogs',
  async (params: IBannersInCatalogsParams) => {
    const response = await axios.get(api.banners.get(), { params });

    if (!response?.data?.items?.length) {
      return null;
    }

    return response?.data?.items;
  });

export const picksSlice = createSlice<IPicksState, any>({
  name: 'picks',
  initialState: {
    bestEvents: [],
    best: [],
    bestTicketsBundles: [],
    mainBanners: [],
    bannersInCatalogs: [],
    bestOverviews: [],
    bestRoutes: [],
    bestPlaces: [],
    bestRestaurants: [],
    bestHotels: [],
    topPushkinEvents: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadBestEventsThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.bestEvents = payload;
      }
    });

    builder.addCase(loadBestThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.best = payload;
      }
    });

    builder.addCase(loadBestTicketsBundlesThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.bestTicketsBundles = payload;
      }
    });

    builder.addCase(loadMainBannersThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.mainBanners = payload;
      }
    });

    builder.addCase(loadBestOverviewsThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.bestOverviews = payload;
      }
    });

    builder.addCase(loadBestRoutesThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.bestRoutes = payload;
      }
    });

    builder.addCase(loadBestPlacesThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.bestPlaces = payload;
      }
    });

    builder.addCase(loadBestRestaurantsThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.bestRestaurants = payload;
      }
    });

    builder.addCase(loadBestHotelsThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.bestHotels = payload;
      }
    });

    builder.addCase(loadBannersInCatalogs.fulfilled, (state, { payload }) => {
      if (payload) {
        state.bannersInCatalogs = payload;
      }
    });

    builder.addCase(loadTopPushkinEventsThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.topPushkinEvents = payload;
      }
    });
  }
});
