/* eslint-disable max-len */
import { envVal } from '@root/config/config';
import moment from 'moment';
import { getStreamPlaceOrEvent } from "@kursk/content/contentUtils";

const year = new Date().getFullYear();

export const astrakhanContent = {
  timezone: 'Europe/Astrakhan',
  yandexMapState: {
    center: [46.347614, 48.030178],
    zoom: 9,
  },
  locale: { _id: 178 },
  title: 'Туристический портал Астраханской области — VizitAstra.ru',
  subTitle: 'Туристический портал Астраханской области',
  mainBanner: {
    hide: true,
    title: 'Астраханская область',
    text: 'Край бесконечных степей, лотосовых полей и полноводных рек',
  },
  useBvi: false,
  phone: '+7 (8512) 51-01-16',
  mail: 'info@vizitastra.ru',
  agreementLink: '/public_offer',
  agreementFilePath: '/docs/public_offer_18_01_2023.pdf',
  socials: {
    // ok: 'https://ok.ru/group/59637846179866',
    vk: 'https://vk.com/vizitastra',
    // yaDzen: 'https://zen.yandex.ru/id/62330928ffe8ad75b0cad210',
    telegram: 'https://t.me/vizitastra',
    // facebook: 'https://www.facebook.com/%D0%A1%D0%BE%D0%BB%D0%BE%D0%B2%D1%8C%D0%B8%D0%BD%D1%8B%D0%B9' +
    //   '-%D0%BA%D1%80%D0%B0%D0%B9-110430014815223/?ref=page_internal',
    // instagram: 'https://www.instagram.com/welcomekursk/',
    rutube: 'https://rutube.ru/channel/26702844'
  },
  socialTitles: {
    vk: 'вконтакте',
    telegram: 'telegram',
  },
  subscribeForm: {
    title: 'Будьте в курсе всех новостей',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Пушкинская карта', to: '/events/tag-pushkinskaya-karta' },
          { title: 'Концерты и шоу', to: '/events/tag-koncerty-i-shou' },
          { title: 'Спектакли', to: '/events/tag-spektakli' },
          { title: 'Выставки', to: '/events/tag-vystavki' },
          { title: 'Мастер-классы', to: '/events/tag-master-klassy' },
          { title: 'Кино', to: '/movies' },
          { title: 'Фестивали', to: '/events/tag-festivali' },
          { title: 'Праздники', to: '/events/tag-prazdniki' }
        ]
      },
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Экскурсии', to: '/excursions' },
      { title: 'Туры', to: '/tours' },
      { title: 'Астраханский сувенир', to: '/goods' },
      {
        title: 'Еще',
        links: [
          { title: 'Где разместиться', to: 'https://hotels.vizitastra.ru' },
          { title: 'Где поесть', to: '/restaurants' },
          { title: 'Места', to: '/places' },
          { to: '/streams', title: 'Трансляции' },
          { title: 'Обзоры', to: '/overviews' },
        ]
      },
    ],
  },
  footer: {
    title: '',
    logoPath: '/images/logo_footer.svg',
    systemName: `© ${year}. Туристический портал Астраханской области — VizitAstra.ru. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      { title: 'Маршруты', to: '/touristroutes' },
      { title: 'Места', to: '/places' },
      { title: 'Где разместиться', to: 'https://hotels.vizitastra.ru' },
      { title: 'Где поесть', to: '/restaurants' },
      { title: 'Экскурсии', to: '/excursions' },
      { title: 'Туры', to: '/tours' },
      { title: 'Новости', to: '/news' },
      { title: 'Обзоры', to: '/overviews' },
      { title: 'Афиша', to: '/events' },
      { title: 'Абонементы', to: '/ticketsbundles' },
      { title: 'Астраханский сувенир', to: '/goods' },
      { title: 'Пушкинская карта', to: '/pushkincard' },
    ],
    callTime: 'В будние дни с 8:30 до 17:30'
  },
  analytics: {
    yandex: envVal({ production: '' }, null),
    gtm: envVal({ production: '' }, null),
    pixel: envVal({ production: '' }, null),
    goals: {
      paidVisit: 'events_visit_astrakhan',
      paidBuy: 'buy_ticket_astrakhan',
      freeVisit: 'events_visits_astrakhan',
      freeNoTicketsVisit: 'noactive_free_astrakhan',
      freeBuy: 'freeandzero_astrakhan',
      externalLinkVisit: 'events_visit_redirect_astrakhan',
      externalLinkBuy: 'buy_redirect_astrakhan',
      ticketsAdd: 'entity_ticket_add',
    }
  },
  unisenderNewsPopup: {
    id: envVal({
      staging: '136452',
    }, null),
  },
  componentsConfig: {
    header: 'default',
    footer: 'v2',
    heroBlock: 'banner',
    socialCardIconVk: 'v2',
    socialCardIconTg: 'v2',
    placesWithMap: true,
    restaurantsWithMap: true,
    pages: {
      index: {},
      places: {},
      singlePlace: {},
    },
  },
  seo: {
    main: 'Туристический портал Астраханской области — VizitAstra.ru',
    pages: {
      index: {
        title: () => 'Культурно-туристический портал Астраханской области',
        description: () => 'Культурно-туристический портал Астраханской области: события, места, маршруты, обзоры, гостиницы и рестораны, трансляции и виртуальные музеи',
        titleOg: () => 'Культурно-туристический портал Астраханской области'
      },
      events: {
        title: () => `Куда сходить в Астрахани ${year}. Афиша ближайших мероприятий Астрахани. Интересные развлекательные события. Официальный сайт - VIZIT ASTRA`,
        description: () => 'Куда можно пойти в Астрахани. Афиша интересных мероприятий в Астраханской области. Подробная информация о событиях: описания, места, расписания, ☎ телефоны. Официальный сайт Астраханской области',
        titleOg: () => '',
      },
      places: {
        title: () => `Все интересные места и достопримечательности Астраханской области ${year}. Официальный сайт - VIZIT ASTRA`,
        description: () => `Интересные и необычные достопримечательности в Астрахани — описания, цены на билеты, адреса, режим работы, фотографии, адреса на карте. Официальный сайт Астраханской области`,
        titleOg: () => ''
      },
      routes: {
        title: () => `Туристические маршруты в Астраханской области ${year}. Официальный сайт - VIZIT ASTRA`,
        description: () => `Лучшие туристические маршруты в Астрахани, которые можно пройти пешком или проехать на автомобиле. Карты туристических маршрутов, описание, необычные идеи для путешествия в Астрахани. Официальный сайт Астраханской области`,
        titleOg: () => ''
      },
      overviews: {
        title: () => `Обзоры ${year} — Астраханская область. Официальный сайт - VIZIT ASTRA`,
        description: () => `Обзоры лучших событий, мест ${year} — Астрахань. Официальный сайт Астраханской области`,
        titleOg: () => ''
      },
      hotels: {
        title: () => `Гостиницы в Астраханской области ${year}. Официальный сайт - VIZIT ASTRA`,
        description: () => 'Гостиницы в Астрахани. Подробная информация: описания, адрес, режими работы, фото, адреса на карте, рейтинги. Официальный сайт Астраханской области',
        titleOg: () => ''
      },
      restaurants: {
        title: () => `Кафе и рестораны в Астраханской области ${year}. Официальный сайт - VIZIT ASTRA`,
        description: () => 'Рестораны и кафе в Астрахани. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт Астраханской области',
        titleOg: () => ''
      },
      streams: {
        title: () => `Трансляции на сайте. Официальный сайт - VIZIT ASTRA`,
        description: () => `Все трансляции и площадки их проведения, виртуальные залы, виртуальные музеи, события. Видео о регионе. Официальный сайт Астраханской области`,
        titleOg: () => '',
        h1: () => `Трансляции на сайте. Официальный сайт - VIZIT ASTRA`,
      },
      excursions: {
        title: () => `Экскурсии по Астраханской области ${year}. Официальный сайт - VIZIT ASTRA`,
        description: () => 'Экскурсии в Астрахани и Астраханской области. Подробная информация об экскурсиях: цены, описания, места, фотографии, телефоны. Официальный сайт Астраханской области',
        titleOg: () => ''
      },
      tours: {
        title: () => `Туры по Астраханской области ${year}. Официальный сайт - VIZIT ASTRA`,
        description: () => 'Туры по Астрахани и Астраханской области. Подробная информация о турах: цены, описания, места, фотографии, телефоны. Официальный сайт Астраханской области',
        titleOg: () => ''
      },
      news: {
        title: () => `Новости Астраханской области ${year}. Официальный сайт - VIZIT ASTRA`,
        description: () => 'Новости Астрахани и Астраханской области. Официальный сайт Астраханской области',
        titleOg: () => ''
      },
      marketPlaces: {
        title: () => 'Купить сувениры в Астрахани. Астраханский сувенир. Официальный сайт - VIZIT ASTRA',
        description: () => 'Магазины сувениры в Астрахани. Подробная информация: описания, фото, цены. Астраханский сувенир. Официальный сайт Астраханской области',
        titleOg: () => ''
      },
      singleEvent: {
        title: ({ entity }) => `${entity.name} — описание, программа мероприятия, дата, время.
         Адрес места проведения — ${entity.address}. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `${entity.name}. Место проведения — ${entity.placeName},
         дата проведения — ${entity.eventDate ? moment(entity.eventDate).format('D MMMM YYYY') : ''}, адрес места проведения — ${entity.address}.
          Подробная информация: описание, фото, адрес. Официальный сайт Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singlePlace: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}.
        Фото, режим работы, афиша и события. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все туристические места и достопримечательности на сайте Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRoute: {
        title: ({ entity }) => `${entity.name}. Подробная информация: описание, адреса мест на карте, фото. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `${entity.name}. Лучшие туристические маршруты, которые можно исходить пешком или проехать на автомобиле. Официальный сайт Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleOverview: {
        title: ({ entity }) => `${entity.name}. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `Обзор - ${entity.name}. Официальный сайт Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleHotel: {
        title: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address}.
         Фото, режим работы. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address} Подробная информация:
         описание, адрес на карте, время работы, телефон, фотографии. Все гостиницы на сайте Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleRestaurant: {
        title: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address}.
         Фото, режим работы. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address} Подробная информация:
         описание, адрес на карте, время работы, телефон, фотографии. Все рестораны на сайте Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleStream: {
        title: ({ entity }) => `Трансляция ${entity.name}. Место проведения — ${getStreamPlaceOrEvent(entity)}. Прямая трансляция на официальном сайте - VIZIT ASTRA`,
        description: ({ entity }) => `${entity.name}. Место проведения — ${getStreamPlaceOrEvent(entity)}. Дата и время проведения — ${entity.liveDate ? moment(entity.liveDate).format('D MMMM YYYY H:mm') : ''} на официальном сайте Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleExcursion: {
        title: ({ entity }) => `${entity?.name}. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `${entity?.name}. Подробная информация об экскурсии: цены, описания, места, фотографии, телефоны. Официальный сайт Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleTour: {
        title: ({ entity }) => `${entity?.name}. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `${entity?.name}. Подробная информация о туре: цены, описания, места, фотографии, телефоны. Официальный сайт Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleNews: {
        title: ({ entity }) => `${entity?.name}. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `${entity?.name}. Официальный сайт Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      singleMarketPlace: {
        title: ({ entity }) => `Купить ${entity?.name}. Астраханский сувенир. Официальный сайт - VIZIT ASTRA`,
        description: ({ entity }) => `Купить ${entity?.name}. Магазины сувениры в Астрахани. Подробная информация: описания, фото, цены. Астраханский сувенир. Официальный сайт Астраханской области`,
        titleOg: ({ entity }) => `${entity.name}`
      },
      pushkincard: {
        title: () => `Куда сходить в Астрахани по Пушкинской карте ${year}. Афиша ближайших мероприятий по Пушкинской карте в Астрахани. Интересные развлекательные события. Официальный сайт - VIZIT ASTRA`,
        description: () => 'Афиша Пушкинской карты в Астрахани. Интересных мероприятия в Астраханской области. Подробная информация о событиях: описания, места, расписания, ☎ телефоны. Официальный сайт Астраханской области',
      },
      main: { title: 'Туристический портал Астраханской области — VizitAstra.ru' },
      artefacts: {
        title: () => 'Дополненная реальность. Официальный сайт - VIZIT ASTRA',
        description: () => 'Дополненная реальность.  Официальный сайт Астраханской области'
      },
      search: {
        title: ({ searchQuery }) => `Поиск событий, мест, маршрутов, обзоров,
        ресторанов, гостиниц, абонементов по запросу «${searchQuery}»`,
        description: ({ searchQuery }) => `События, места, маршруты, обзоры,
        рестораны, гостиницы, абонементы по запросу «${searchQuery}»`,
      },
      faq: {
        title: () => 'Часто задаваемые вопросы. Официальный сайт - VIZIT ASTRA',
        description: () => 'Часто задаваемые вопросы. Официальный сайт Астраханской области',
      },
      contacts: {
        title: () => 'Контакты. Официальный сайт - VIZIT ASTRA',
        description: () => 'Контакты. Официальный сайт Астраханской области',
      },
    },
  },
  homePageBlocks: [
    'best',
    'places',
    'excursions',
    'routes',
    'tours',
    'subscribe',
    'overviews',
    'news',
    'events',
    'socials',
    'restaurants',
  ]
};
