import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { ITour } from "../types/tour";
// import { getConfig } from "../../../../config/config";

interface IState {
  tour?: ITour;
  durations?: {
    min: number;
    max: number;
  };
}

export const loadTour = createAsyncThunk<any, any>('tourSlice/loadTour',
  async (params: any) => {
    const newParams = {
      ...params,
      schedulesActiveDays: true,
    };
    const response = await axios.get(api.tours.getOne(params._id), { params: newParams });
    return {
      tour: response.data.tour,
    };
  }
);

export const loadDurations = createAsyncThunk<any, never>(
  `tourSlice/loadDurations`,
  async () => {
    const { data } = await axios.get(api.tours.get(), { params: { meta: 'tourPrograms', limit: 1 } });
    const { tourPrograms } = data.meta;
    return {
      durations: {
        min: tourPrograms.min || 0,
        max: tourPrograms.max || 0,
      }
    };
  });


export const tourSlice = createSlice<IState, any>({
  name: 'tourSlice',
  initialState: {},
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadTour.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(loadDurations.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});
