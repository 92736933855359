import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import { api } from "../../urls";
import { IStream } from "../types/stream";

interface IState {
  items: IStream[];
}

export const loadStreamsBannersThunk = createAsyncThunk<any, any>('streams/loadStreamsBannersThunk', async () => {
  const response = await axios.get(api.streams.get(), {
    params: {
      limit: 4,
      sort: 'start',
      start: moment().valueOf(),
    }
  })

  if (!response?.data?.streams) {
    return null;
  }

  return {
    items: response?.data.streams,
  };
});

export const streamsBannersSlice = createSlice<IState, any>({
  name: 'streamsBanners',
  initialState: {
    items: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadStreamsBannersThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.items = payload.items;
      }
    });
  }
});
