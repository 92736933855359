import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { IPlace } from "../types/places";

interface IState {
  restaurants: IPlace[]
  restaurant?: IPlace;
}

export const loadRestaurant = createAsyncThunk<any, any>('restaurantsList/loadRestaurant',
  async (params: any) => {
    const response = await axios.get(api.restaurants.getOne(params._id));
    return {
      restaurant: response.data.place || null,
    };
  }
);

export const restaurantsListSlice = createSlice<IState, any>({
  name: 'restaurantsList',
  initialState: {
    restaurants: [],
  },
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadRestaurant.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});

export const { setActiveMobileTab } = restaurantsListSlice.actions;
