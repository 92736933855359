import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';

export interface ISproject {
  _id: number;
  name: string;
  path: string;
  type?: string;
  importData?: {
    publicKey?: string,
    secretKey?: string,
    project?: {
      id: string
    }
  },
  lastImportDate?: number;
  isPublished?: boolean;
  importStatus?: string;
  isImported?: boolean;
  createDate?: number;
  updateDate?: number;
}
interface IState {
  sprojects?: ISproject[]
}

export const loadSprojects = createAsyncThunk('sprojects/load',
  async () => {
    const response = await axios.get(api.sprojects());
    return {
      sprojects: response.data.sprojects
    };
  }
);

export const sprojectsSlice = createSlice<IState, any>({
  name: 'sprojects',
  initialState: {
    sprojects: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSprojects.fulfilled, (state, { payload }) => {
      state.sprojects = payload.sprojects;
    });
  }
});
