import '../styles/index.scss';
import App, { AppInitialProps } from 'next/app';
import MobileDetect from 'mobile-detect';
import { AnalyticsPageView } from "@common/components/Analytics/AnalyticsPageView";
import { GlobalLoader } from "@common/components/GlobalLoader/GlobalLoader";
import { Loader } from "@kursk/components/ui/Loader/Loader";
import { reduxWrapper } from "@common/redux/store";
import moment from "moment";
import "moment-timezone";
import 'moment/locale/ru';
import { AUTH_TOKEN_NAME, loadToken } from "@common/redux/slices/auth.slice";
import { loadSprojects } from "@common/redux/slices/sprojects.slice";
import { regionContent } from '@kursk/content/regionContent';
import { OrientationContext } from "@common/hooks/useOrientation";
import { Orientations } from "@common/utils/constants";

moment.tz.setDefault(regionContent.timezone);
moment.locale('ru');

class MyApp extends App<AppInitialProps> {

  public static getInitialProps = async ({ Component, ctx }) => {
    await ctx.store.dispatch(loadSprojects());

    // Если в куках есть токен пользователя, загружаем иинформацию о нем в стор
    if (ctx?.req?.cookies[AUTH_TOKEN_NAME]) {
      await ctx.store.dispatch(loadToken({ token: ctx.req.cookies[AUTH_TOKEN_NAME] }))
    }
    const props = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    let deviceType: Orientations = 'desktop';
    const md = new MobileDetect(ctx?.req?.headers['user-agent']);
    if (md.tablet()) {
      deviceType = 'tablet'
    } else if (md.mobile() || md.phone()) {
      deviceType = 'mobile';
    }
    return {
      pageProps: {
        ...props,
        pathname: ctx.pathname,
        orientation: deviceType,
      },
    };
  };

  public render() {
    const { Component, pageProps } = this.props;
    return (
      <OrientationContext.Provider value={pageProps.orientation}>
        <Component {...pageProps} />
        <GlobalLoader renderLoader={() => (<Loader fixed/>)}/>
        <AnalyticsPageView />
      </OrientationContext.Provider>
    )
    ;
  }
}


export default reduxWrapper.withRedux(MyApp)
