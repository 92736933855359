import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import moment from 'moment';
import _ from "lodash";
import { api, paramsSerializer, paramsToQuery } from "../../urls";
import { IEvent } from "../types/event";
import { SliceAction } from "../types/sliceAction";
import { IHotel, IPlace, IRestaurant } from "../types/places";
import { IStream } from "../types/stream";
import { IBaseTag, IGenre, ITag } from "../types/tags";
import { push } from "connected-next-router";
import { ParsedUrlQuery } from 'querystring';
import { INews, IOverview, IRoute } from "../types/articles";
import { EntityType, ILocale } from "../types/common";
import { ITicketsBundles } from "../types/ticketsBundles";
import { IMovie } from "../types/movie";
import { loadEventPlaces } from "./eventsPlaces.slice";
import { generateQuery } from "../../query/generateQuery";
import { loadStreamsPlaces } from "./streamsPlaces.slice";
import { IExcursion } from "../types/excursion";
import { ITour } from "../types/tour";
import { ICheckboxSelectOption } from "../../../kursk/components/ui/CheckboxSelect/CheckboxSelect";
import { IGoods } from "../types/goods";

const FIELDS = {
  events: [
    '_id', 'actualSeances', 'category', 'description',
    'eipskPrices', 'externalSaleLink', 'image',
    'isFree', 'locales', 'name', 'schedules', 'sysName',
    'type', 'organization', 'source'
  ],
  places: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName', 'ticketsInfo'
  ],
  halls: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName'
  ],
  museums: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName'
  ],
  hotels: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName', 'ticketsInfo'
  ],
  restaurants: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName', 'ticketsInfo'
  ],
  overviews: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName', 'ticketsInfo'
  ],
  news: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName', 'ticketsInfo',
    'updateDate'
  ],
  routes: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName', 'ticketsInfo'
  ],
  touristroutes: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName', 'ticketsInfo'
  ],
  ticketsbundles: [
    '_id', 'ageRestriction', 'name', 'sysName',
    'maxPrice', 'minPrice', 'fee', 'image',
    'description', 'start', 'end', 'endTimezone',
    'startTimezone', 'originalPrice', 'batches'
  ],
  artefacts: [
    '_id', 'category', 'description', 'externalSaleLink',
    'image', 'locales', 'name', 'sysName', 'ticketsInfo'
  ],
  excursions: [
    '_id', 'actualSeances', 'category', 'description', 'duration',
    'externalSaleLink', 'image',
    'isFree', 'locales', 'name', 'schedules', 'sysName',
    'type', 'organization', 'source'
  ],
  tours: [
    '_id', 'actualSeances', 'category', 'description',
    'externalSaleLink', 'image',
    'isFree', 'locales', 'name', 'schedules', 'sysName',
    'type', 'organization', 'source', 'tourProgram'
  ],
  goods: null
};

export const FILTERS_BY_ENTITY_TYPE = {
  events: [
    'allTags', 'places', 'locales', 'isFree', 'start', 'end', 'minPrice', 'maxPrice', 'withTickets'
  ],
  places: [
    'allTags', 'locales', 'isFree', 'minPrice', 'maxPrice', 'withTickets', 'priceSource', 'hasAudio'
  ],
  hotels: [
    'allTags', 'locales', 'isFree', 'minPrice', 'maxPrice', 'withTickets', 'priceSource'
  ],
  restaurants: [
    'allTags', 'locales', 'isFree', 'minPrice', 'maxPrice', 'withTickets', 'priceSource'
  ],
  overviews: [
    'allTags'
  ],
  news: [
    'allTags'
  ],
  routes: [
    'allTags'
  ],
  touristroutes: [
    'allTags',
    'routeTypes',
    'durationFrom',
    'durationTo',
    // 'locales', выключаем
    'hasAudio'
  ],
  streams: [
    'allTags', 'places', 'isLive', 'isPreview', 'isArchive', 'start', 'end'
  ],
  ticketsbundles: [
    'allTags', 'places', 'start', 'end', 'isFree', 'minPrice', 'maxPrice'
  ],
  movies: ['start', 'end', 'genres'],
  halls: ['allTags'],
  museums: ['allTags'],
  artefacts: ['allTags'],
  excursions: [
    'allTags',
    'locales',
    'transportationMethods',
    'durationFrom',
    'durationTo',
    'minPrice',
    'maxPrice',
    'withTickets',
    'isFree',
    'organizations'
  ],
  tours: [
    'allTags',
    'locales',
    'transportationMethods',
    'durationFrom',
    'durationTo',
    'minPrice',
    'maxPrice',
    'withTickets',
    'isFree',
    'organizations'
  ],
}

interface LoadOptions {
  status: string;
  isFree: any;
  fields: any;
  sort: any;
  meta: string;
  limit: number;
  offset: number;
  start: any;
  minPrice: number;
  maxPrice: number;
  durationFrom?: number;
  durationTo?: number;
}

export interface IMeta {
  tags?: ITag[];
  genres?: IGenre[];
  places?: IPlace[];
  locales?: ILocale[];
  types?: IGenre[];
  durations?: { min: number, max: number }
  tourPrograms?: { min: number, max: number }
  organizations?: ICheckboxSelectOption[]
}

export interface IFilters {
  allTags?: number[];
  genres?: number[];
  places?: number[];
  locales?: number[];
  isFree?: boolean;
  withTickets?: boolean;
  hasAudio?: boolean;
  isLive?: boolean;
  isPreview?: boolean;
  isArchive?: boolean;
  strictDate?: number;
  nowDate?: number;
  start?: number;
  end?: number;
  priceSource?: string;
  minPrice?: number;
  maxPrice?: number;
  routeTypes?: string[];
  durationTo?: number,
  durationFrom?: number,
  nearestStreams?: boolean,
  type?: number[],
  transportationMethods?: string[],
  organizations?: number[]
}

function getEntitySlice<T>(entityType: EntityType) {

  interface IState {
    items: T[];
    total: number;
    offset: number;
    filters: IFilters;
    meta: IMeta;
  }

  interface ILoadThunkReturned {
    items: T[];
    total: number;
    meta?: IMeta;
  }

  const RETURN_LOAD_ITEMS_NAME_BY_ENTITY_TYPE = {
    hotels: 'places',
    restaurants: 'places',
    ticketsbundles: 'ticketsBundles',
    routes: 'articles',
    touristroutes: 'touristRoutes',
    overviews: 'articles',
    news: 'articles',
    movies: 'movies',
    halls: 'halls',
    museums: 'museums',
    artefact: 'artefacts',
  }

  function getSort() {
    const SORT_BY_ENTITY_TYPE = {
      events: {
        rating: -1,
      },
      places: {
        updateDate: -1,
      },
      hotels: {
        updateDate: -1,
      },
      restaurants: {
        updateDate: -1,
      },
      streams: {
        start: -1,
      },
      ticketsbundles: {
        end: 1
      },
      movies: {
        'nearestSeance.start': 1,
        'nearestSeance.end': -1,
      },
      museums: {
        start: 1,
      }
    };

    return SORT_BY_ENTITY_TYPE[entityType];
  }

  function addEndFilter(options: any) {
    if (_.has(options, 'start') && !_.isNaN(Number(options.start)) && Number(options.start) !== 0) {
      let end: number;
      if (_.has(options, 'end') && !_.isNaN(Number(options.end)) && options.end !== 0) {
        end = moment(options.end).endOf('day').valueOf();
      } else {
        end = moment(options.start).endOf('day').valueOf();
      }
      Object.assign(options, { end });
    }
  }
  function getgoodsLoadOptions(state: IState) {
    const options: Partial<LoadOptions> = {
      limit: 20,
      offset: state.offset,
    };
    return options;
  }
  function getLoadOptions(state: IState) {
    const sort = getSort();
    const fields = FIELDS[entityType];
    const filters = FILTERS_BY_ENTITY_TYPE[entityType];

    const options: Partial<LoadOptions> = {
      fields,
      sort,
      meta: entityType === 'events' ? 'locales,tags' : '1',
      limit: 20,
      offset: state.offset,
      start: undefined,
      ...entityType === 'ticketsbundles' && { status: 'available', isFree: undefined },
      ..._(state.filters).pick(filters).omitBy((value) => _.isNil(value) || _.isNaN(value) || value === 0).value(),
    };

    if (state.filters.start) {
      if (state.filters.nowDate) {
        Object.assign(options, { start: state.filters.nowDate });
      }
      addEndFilter(options);
    } else if (state.filters.nowDate) {
      Object.assign(options, { start: state.filters.nowDate });
    }

    if (options.minPrice) options.minPrice = options.minPrice * 100;
    if (options.maxPrice) options.maxPrice = options.maxPrice * 100;

    if (entityType === 'ticketsbundles' && _.has(options, 'isFree')) {
      return {
        ..._(options).omit('isFree').value(),
        ...options.isFree && {
          maxPrice: 0,
          minPrice: 0
        }
      }
    }
    if (entityType === 'excursions') {
      if (options.durationFrom) options.durationFrom = options.durationFrom * 60;
      if (options.durationTo) options.durationTo = options.durationTo * 60;
    }

    return options;
  }

  function getStreamsLoadOptions(state: IState) {
    const filters = state.filters;
    const options = {
      sort: {
        start: -1,
      },
      meta: 1,
      limit: 20,
      offset: state.offset,
    };

    if (filters.start) {
      if (filters.nearestStreams) {
        Object.assign(options, {
          start: filters.start,
          sort: 'start',
        });
      } else {
        Object.assign(options, {
          start: filters.start,
          sort: null,
        });
      }

      if (filters.end) {
        Object.assign(options, {
          end: filters.end,
        });
      }
      if (!filters.nearestStreams) addEndFilter(options);
    }

    if (filters.isLive) {
      Object.assign(options, {
        start: filters.strictDate,
        end: filters.strictDate,
        sort: {
          end: -1,
        }
      });
    }

    if (filters.isPreview) {
      Object.assign(options, {
        strictStart: filters.strictDate,
        sort: {
          start: 1,
        }
      });
    }

    if (filters.isArchive) {
      Object.assign(options, {
        strictEnd: filters.strictDate,
        sort: {
          start: 1,
        }
      });
    }

    if (filters.places.length > 0) {
      Object.assign(options, {
        places: filters.places,
      });
    }

    if (filters.allTags.length > 0) {
      Object.assign(options, {
        allTags: filters.allTags,
      });
    }

    return options;
  }

  const loadEntities = createAsyncThunk<ILoadThunkReturned, any>
  (`${entityType}/loadEntities`, async (params, { getState, dispatch }) => {
    const state = getState()[entityType] as IState;
    let options = {};
    if (entityType === 'streams') {
      options = getStreamsLoadOptions(state);
    } else if (entityType === 'goods') {
      options = getgoodsLoadOptions(state)
    } else {
      options = getLoadOptions(state)
    }
    const returnLoadItemsName = RETURN_LOAD_ITEMS_NAME_BY_ENTITY_TYPE[entityType] || entityType;
    const apiObj = api[entityType] as any;

    // Load places if events
    if (entityType === 'events') {
      await dispatch(loadEventPlaces({
        ids: state.filters.places,
        ...options
      }));
    }
    // Load places if streams
    if (entityType === 'streams') {
      await dispatch(loadStreamsPlaces({
        ids: state.filters.places,
        ...options
      }));
    }

    const response = await axios.get(
      apiObj.get(),
      { params: paramsToQuery({ ...options, ...params }), paramsSerializer }
    );

    if (!response?.data[returnLoadItemsName]) {
      return null;
    }

    if (entityType === 'excursions' || entityType === 'tours') {
      // If we have selected organizations what are not in meta
      // (other filter selected after organization, so meta change)
      // fetch selected filter data to add to meta, so selected items show in filter
      const result: number[] = [];
      const organizationsInMeta = response?.data?.meta?.organizations || [];
      const organizationsInMetaIds = organizationsInMeta.map(item => Number(item._id));

      state.filters.organizations.forEach(item => {
        if (!organizationsInMetaIds.includes(item)) {
          result.push(item);
          return;
        }
      });
      if (result.length > 0) {
        const res = await axios.get(
          api.organizations.get(),
          { params: { ids: result.join(','), fields: '_id,name' } }
        );
        response.data.meta.organizations = [...response.data.meta.organizations, ...res.data.organizations]
      }
    }

    return {
      items: response?.data[returnLoadItemsName],
      total: response?.data?.total,
      meta: response?.data?.meta
    };
  });

  const changeQueryFilters = createAsyncThunk<any, any>(
    `${entityType}/changeQueryFilters`,
    async (params, { getState, dispatch }) => {
      const state = getState()[entityType] as IState;
      // const result = changeQueryFiltersHandler(state.filters, entityType, state.meta, getState());
      const result = generateQuery(state.filters, entityType, state.meta, getState());
      dispatch(push(result));
    }
  );

  const entitiesSlice = createSlice<IState, any>({
    name: entityType,
    initialState: {
      items: [],
      total: 0,
      offset: 0,
      filters: {
        allTags: [],
        genres: [],
        places: [],
        locales: [],
        routeTypes: [],
        hasAudio: false,
        isFree: false,
        isLive: false,
        isPreview: false,
        isArchive: false,
        strictDate: null,
        nowDate: null,
        start: null,
        end: null,
        durationTo: 0,
        durationFrom: 0,
        nearestStreams: false,
        transportationMethods: [],
        organizations: [],
      },
      meta: {
        tags: [],
        places: [],
        organizations: []
      }
    },
    reducers: {
      setOffset(state: IState, { payload }: PayloadAction<number>) {
        state.offset = payload;
      },
      setFilters(state: IState, { payload }: PayloadAction<any>) {
        const filtersFields: string[] = [
          'locales', 'places', 'isFree', 'withTickets', 'isLive', 'isPreview',
          'isArchive', 'strictDate', 'nowDate', 'allTags', 'start', 'end',
          'maxPrice', 'minPrice', 'priceSource', 'genres', 'routeTypes',
          'durationFrom', 'durationTo', 'nearestStreams', 'hasAudio', 'transportationMethods', 'organizations'
        ];
        filtersFields.forEach((i) => {
          if (_.has(payload, i)) {
            switch (i) {
              case 'locales': {
                state.filters[i] = _(payload[i]).map(Number).reject(_.isNaN).value();
                break;
              }
              case 'places': {
                state.filters[i] = _(payload[i]).map(Number).reject(_.isNaN).value();
                break;
              }
              case 'start': {
                state.filters[i] = Number(payload[i]);
                break;
              }
              case 'end': {
                state.filters[i] = Number(payload[i]);
                break;
              }
              case 'withTickets': {
                state.filters[i] = Boolean(payload[i]);
                break;
              }
              case 'maxPrice': {
                state.filters[i] = Number(payload[i]);
                break;
              }
              case 'minPrice': {
                state.filters[i] = Number(payload[i]);
                break;
              }
              case 'isFree': {
                state.filters[i] = Boolean(payload[i]);
                break;
              }
              default: {
                state.filters[i] = payload[i];
              }
            }
          }
        });
      },
      setTags(state: IState, { payload }: PayloadAction<ITag>) {
        if (state.filters.allTags.includes(payload._id)) {
          state.filters.allTags = state.filters.allTags.filter((value) => value !== payload._id);
        } else {
          state.filters.allTags = [...state.filters.allTags, payload._id];
        }
      },
      setGenres(state: IState, { payload }: PayloadAction<ITag>) {
        if (state.filters.genres.includes(payload._id)) {
          state.filters.genres = state.filters.genres.filter((value) => value !== payload._id);
        } else {
          state.filters.genres = [...state.filters.genres, payload._id];
        }
      }
    },
    extraReducers: (builder) => {
      builder.addCase(loadEntities.fulfilled, (state, { payload }) => {
        if (payload) {
          if (state.offset === 0) {
            state.items = payload.items as any;
            state.total = payload.total;
            state.meta = { ...payload.meta };
            if (payload.meta) {
              if (payload?.meta?.genres) {
                state.meta.genres = _.sortBy(payload.meta.genres, ({ count }) => -count);
              } else {
                state.meta.tags = _.sortBy(payload.meta.tags, ({ count }) => -count);
              }
              state.meta.places = _.sortBy(payload.meta.places, ({ count }) => -count);
            }
          } else {
            state.items = [...state.items, ...payload.items] as any;
            state.total = payload.total;
          }
        }
      });
    }
  });

  return {
    entitiesSlice,
    loadEntities,
    changeQueryFilters,
  }
}

// ENTITIES
// EVENT
const eventSliceData = getEntitySlice<IEvent>('events');

export const eventsSlice = eventSliceData.entitiesSlice;
export const loadEvents = eventSliceData.loadEntities;
export const changeQueryFiltersEvents = eventSliceData.changeQueryFilters;
export const setEventsOffset = eventsSlice.actions.setOffset as SliceAction;
export const setEventsFilters = eventsSlice.actions.setFilters as SliceAction;
export const setEventsTags = eventsSlice.actions.setTags as SliceAction;

// PLACES
const placeSliceData = getEntitySlice<IPlace>('places');

export const placesSlice = placeSliceData.entitiesSlice;
export const loadPlaces = placeSliceData.loadEntities;
export const changeQueryFiltersPlaces = placeSliceData.changeQueryFilters;
export const setPlacesOffset = placesSlice.actions.setOffset as SliceAction;
export const setPlacesFilters = placesSlice.actions.setFilters as SliceAction;
export const setPlacesTags = placesSlice.actions.setTags as SliceAction;

// HOTELS
const hotelSliceData = getEntitySlice<IHotel>('hotels');

export const hotelsSlice = hotelSliceData.entitiesSlice;
export const loadHotels = hotelSliceData.loadEntities;
export const changeQueryFiltersHotels = hotelSliceData.changeQueryFilters;
export const setHotelsOffset = hotelsSlice.actions.setOffset as SliceAction;
export const setHotelsFilters = hotelsSlice.actions.setFilters as SliceAction;
export const setHotelsTags = hotelsSlice.actions.setTags as SliceAction;

// RESTAURANTS
const restaurantSliceData = getEntitySlice<IRestaurant>('restaurants');

export const restaurantsSlice = restaurantSliceData.entitiesSlice;
export const loadRestaurants = restaurantSliceData.loadEntities;
export const changeQueryFiltersRestaurants = restaurantSliceData.changeQueryFilters;
export const setRestaurantsOffset = restaurantsSlice.actions.setOffset as SliceAction;
export const setRestaurantsFilters = restaurantsSlice.actions.setFilters as SliceAction;
export const setRestaurantsTags = restaurantsSlice.actions.setTags as SliceAction;

// OVERVIEWS
const overviewSliceData = getEntitySlice<IOverview>('overviews');

export const overviewsSlice = overviewSliceData.entitiesSlice;
export const loadOverviews = overviewSliceData.loadEntities;
export const changeQueryFiltersOverviews = overviewSliceData.changeQueryFilters;
export const setOverviewsOffset = overviewsSlice.actions.setOffset as SliceAction;
export const setOverviewsFilters = overviewsSlice.actions.setFilters as SliceAction;
export const setOverviewsTags = overviewsSlice.actions.setTags as SliceAction;

// OVERVIEWS
const newsSliceData = getEntitySlice<INews>('news');

export const newsSlice = newsSliceData.entitiesSlice;
export const loadNews = newsSliceData.loadEntities;
export const changeQueryFiltersNews = newsSliceData.changeQueryFilters;
export const setNewsOffset = newsSlice.actions.setOffset as SliceAction;
export const setNewsFilters = newsSlice.actions.setFilters as SliceAction;
export const setNewsTags = newsSlice.actions.setTags as SliceAction;

// ROUTES
const routeSliceData = getEntitySlice<IRoute>('routes');

export const routesSlice = routeSliceData.entitiesSlice;
export const loadRoutes = routeSliceData.loadEntities;
export const changeQueryFiltersRoutes = routeSliceData.changeQueryFilters;
export const setRoutesOffset = routesSlice.actions.setOffset as SliceAction;
export const setRoutesFilters = routesSlice.actions.setFilters as SliceAction;
export const setRoutesTags = overviewsSlice.actions.setTags as SliceAction;

// TOURISTROUTES
const touristRouteSliceData = getEntitySlice<IRoute>('touristroutes');

export const touristsRoutesSlice = touristRouteSliceData.entitiesSlice;
export const loadTouristRoutes = touristRouteSliceData.loadEntities;
export const changeQueryFiltersTouristRoutes = touristRouteSliceData.changeQueryFilters;
export const setTouristRoutesOffset = touristsRoutesSlice.actions.setOffset as SliceAction;
export const setTouristRoutesFilters = touristsRoutesSlice.actions.setFilters as SliceAction;
export const setTouristRoutesTags = touristsRoutesSlice.actions.setTags as SliceAction;

// STREAMS
const streamsSliceData = getEntitySlice<IStream>('streams');

export const streamsSlice = streamsSliceData.entitiesSlice;
export const loadStreams = streamsSliceData.loadEntities;
export const changeQueryFiltersStreams = streamsSliceData.changeQueryFilters;
export const setStreamsOffset = streamsSlice.actions.setOffset as SliceAction;
export const setStreamsFilters = streamsSlice.actions.setFilters as SliceAction;
export const setStreamsTags = streamsSlice.actions.setTags as SliceAction;

// TICKETSBUNDLES
const ticketsBundlesSliceData = getEntitySlice<ITicketsBundles>('ticketsbundles');

export const ticketsBundlesSlice = ticketsBundlesSliceData.entitiesSlice;
export const loadTicketsBundles = ticketsBundlesSliceData.loadEntities;
export const changeQueryFiltersTicketsBundles = ticketsBundlesSliceData.changeQueryFilters;
export const setTicketsBundlesOffset = ticketsBundlesSlice.actions.setOffset as SliceAction;
export const setTicketsBundlesFilters = ticketsBundlesSlice.actions.setFilters as SliceAction;
export const setTicketsBundlesTags = ticketsBundlesSlice.actions.setTags as SliceAction;

//MOVIES
const movieSliceData = getEntitySlice<IMovie>('movies');

export const moviesSlice = movieSliceData.entitiesSlice;
export const loadMovies = movieSliceData.loadEntities;
export const changeQueryFiltersMovies = movieSliceData.changeQueryFilters;
export const setMoviesOffset = moviesSlice.actions.setOffset as SliceAction;
export const setMoviesFilters = moviesSlice.actions.setFilters as SliceAction;
export const setMoviesGenres = moviesSlice.actions.setGenres as SliceAction;

// EXCURSIONS
const excursionSliceData = getEntitySlice<IExcursion>('excursions');

export const excursionsSlice = excursionSliceData.entitiesSlice;
export const loadExcursions = excursionSliceData.loadEntities;
export const changeQueryFiltersExcursions = excursionSliceData.changeQueryFilters;
export const setExcursionsOffset = excursionsSlice.actions.setOffset as SliceAction;
export const setExcursionsFilters = excursionsSlice.actions.setFilters as SliceAction;
export const setExcursionsTags = excursionsSlice.actions.setTags as SliceAction;

// TOURS
const tourSliceData = getEntitySlice<ITour>('tours');

export const toursSlice = tourSliceData.entitiesSlice;
export const loadTours = tourSliceData.loadEntities;
export const changeQueryFiltersTours = tourSliceData.changeQueryFilters;
export const setToursOffset = toursSlice.actions.setOffset as SliceAction;
export const setToursFilters = toursSlice.actions.setFilters as SliceAction;
export const setToursTags = toursSlice.actions.setTags as SliceAction;

// TOURS
const goodsSliceData = getEntitySlice<IGoods>('goods');

export const goodsSlice = goodsSliceData.entitiesSlice;
export const loadgoods = goodsSliceData.loadEntities;
export const changeQueryFiltersgoods = goodsSliceData.changeQueryFilters;
export const setgoodsOffset = goodsSlice.actions.setOffset as SliceAction;
export const setgoodsFilters = goodsSlice.actions.setFilters as SliceAction;
export const setgoodsTags = goodsSlice.actions.setTags as SliceAction;
// GENERAL FUNCTIONS

export interface IChangeGenresArgs {
  genre: IGenre;
  query: ParsedUrlQuery;
}

export function setTags(type: EntityType, params: IBaseTag) {
  /* eslint-disable indent */
  switch (type) {
    case 'events':
      return setEventsTags(params);
    case 'places':
      return setPlacesTags(params);
    case 'hotels':
      return setHotelsTags(params);
    case 'restaurants':
      return setRestaurantsTags(params);
    case 'overviews':
      return setOverviewsTags(params);
    case 'news':
      return setNewsTags(params);
    case 'routes':
      return setRoutesTags(params);
    case 'touristroutes':
      return setTouristRoutesTags(params);
    case 'streams':
      return setStreamsTags(params);
    case 'ticketsbundles':
      return setTicketsBundlesTags(params);
    case 'movies':
      return setMoviesGenres(params);
    case 'excursions':
      return setExcursionsTags(params);
    case 'tours':
      return setToursTags(params);
  }
  /* eslint-enable indent */
}

export function loadEntities(type: EntityType, params: any, usePick = true, isPushkin = false) {
  /* eslint-disable indent */
  switch (type) {
    case 'events':
      return loadEvents({
        ...params, usePick: usePick ?
          isPushkin ? 'topPushkinEvents' : 'topEvents' : undefined
      });
    case 'places':
      return loadPlaces({ ...params, types: ['place'], usePick: usePick ? 'topPlaces' : undefined });
    case 'hotels':
      return loadHotels({ ...params, types: ['hotel'], usePick: usePick ? 'topHotels' : undefined });
    case 'restaurants':
      return loadRestaurants({ ...params, types: ['restaurant'], usePick: usePick ? 'topRestaurants' : undefined });
    case 'overviews':
      return loadOverviews({ ...params, categorySysNames: 'obzor', usePick: usePick ? 'topOverviews' : undefined });
    case 'news':
      return loadNews({ ...params, categorySysNames: 'novosti' });
    case 'regionvideos':
      return loadOverviews({
        ...Object.assign(params, { hasVideo: 1, fromPickOnly: 'regionVideos' }),
        categorySysNames: 'obzor',
        usePick: undefined
      });
    case 'routes':
      return loadRoutes({ ...params, categorySysNames: 'marshrut', usePick: usePick ? 'topRoutes' : undefined });
    case 'touristroutes':
      return loadTouristRoutes({ ...params, usePick: usePick ? 'topTouristRoutes' : undefined });
    case 'streams':
      return loadStreams({ ...params, usePick: usePick ? 'topStreams' : undefined });
    case 'ticketsbundles':
      return loadTicketsBundles(params);
    case 'movies':
      return loadMovies({ ...params, usePick: usePick ? 'topMovies' : undefined });
    case 'halls':
      return loadPlaces({
        ...params,
        types: ['place'],
        hasVirtualHall: 1
      });
    case 'museums':
      return loadPlaces({
        ...params,
        types: ['place'],
        isVirtualMuseum: 1
      });
    case 'artefacts':
      return loadOverviews({ ...params, isArtefact: 1, usePick: usePick ? 'topOverviews' : undefined })
    case 'excursions':
      return loadExcursions({ ...params, usePick: usePick ? 'topExcursions' : undefined })
    case 'tours':
      return loadTours({ ...params, usePick: usePick ? 'topTours' : undefined })
    case 'goods':
      return loadgoods(params)

  }
  /* eslint-enable indent */
}

export function changeQueryFilters(type: EntityType) {
  /* eslint-disable indent */
  switch (type) {
    case 'events':
      return changeQueryFiltersEvents({});
    case 'places':
      return changeQueryFiltersPlaces({});
    case 'hotels':
      return changeQueryFiltersHotels({});
    case 'restaurants':
      return changeQueryFiltersRestaurants({});
    case 'overviews':
      return changeQueryFiltersOverviews({});
    case 'news':
      return changeQueryFiltersNews({});
    case 'routes':
      return changeQueryFiltersRoutes({});
    case 'touristroutes':
      return changeQueryFiltersTouristRoutes({});
    case 'streams':
      return changeQueryFiltersStreams({});
    case 'ticketsbundles':
      return changeQueryFiltersTicketsBundles({});
    case 'movies':
      return changeQueryFiltersMovies({});
    case 'halls':
      return changeQueryFiltersPlaces({});
    case 'museums':
      return changeQueryFiltersPlaces({});
    case 'artefacts':
      return changeQueryFiltersOverviews({});
    case 'excursions':
      return changeQueryFiltersExcursions({});
    case 'tours':
      return changeQueryFiltersTours({});
    case 'goods':
      return changeQueryFiltersgoods({});
  }
  /* eslint-enable indent */
}

export function setEntitiesOffset(type: EntityType, param: number) {
  /* eslint-disable indent */
  switch (type) {
    case 'events':
      return setEventsOffset(param);
    case 'places':
      return setPlacesOffset(param);
    case 'hotels':
      return setHotelsOffset(param);
    case 'restaurants':
      return setRestaurantsOffset(param);
    case 'overviews':
      return setOverviewsOffset(param);
    case 'news':
      return setNewsOffset(param);
    case 'regionvideos':
      return setOverviewsOffset(param);
    case 'routes':
      return setRoutesOffset(param);
    case 'touristroutes':
      return setTouristRoutesOffset(param);
    case 'streams':
      return setStreamsOffset(param);
    case 'ticketsbundles':
      return setTicketsBundlesOffset(param);
    case 'movies':
      return setMoviesOffset(param);
    case 'halls':
      return setPlacesOffset(param);
    case 'museums':
      return setPlacesOffset(param);
    case 'artefacts':
      return setOverviewsOffset(param);
    case 'excursions':
      return setExcursionsOffset(param);
    case 'tours':
      return setToursOffset(param);
    case 'goods':
      return setgoodsOffset(param);
  }
  /* eslint-enable indent */
}

export function setFilters(type: EntityType, params: any) {
  /* eslint-disable indent */
  switch (type) {
    case 'events':
      return setEventsFilters(params);
    case 'places':
      return setPlacesFilters(params);
    case 'halls':
      return setPlacesFilters(params);
    case 'museums':
      return setPlacesFilters(params);
    case 'hotels':
      return setHotelsFilters(params);
    case 'restaurants':
      return setRestaurantsFilters(params);
    case 'overviews':
      return setOverviewsFilters(params);
    case 'news':
      return setNewsFilters(params);
    case 'regionvideos':
      return setOverviewsFilters(params);
    case 'routes':
      return setRoutesFilters(params);
    case 'touristroutes':
      return setTouristRoutesFilters(params);
    case 'streams':
      return setStreamsFilters(params);
    case 'ticketsbundles':
      return setTicketsBundlesFilters(params);
    case 'movies':
      return setMoviesFilters(params);
    case 'artefacts':
      return setOverviewsFilters(params);
    case 'excursions':
      return setExcursionsFilters(params);
    case 'tours':
      return setToursFilters(params);
    case 'goods':
      return setgoodsFilters(params);
  }
  /* eslint-enable indent */
}
