export const defaultEntitiesNames = {
  touristroutes: {
    nominative: 'маршрут',
    plural: 'маршруты',
    genitive: 'маршрут',
    pluralGenitive: 'маршрутов'
  },
  routes: {
    nominative: 'маршрут',
    plural: 'маршруты',
    genitive: 'маршрут',
    pluralGenitive: 'маршрутов'
  },
  overview: {
    nominative: 'обзор',
    plural: 'обзоры',
    genitive: 'обзор',
    pluralGenitive: 'обзоров'
  }
}
