import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { IEvent } from "../types/event";
import { getConfig } from "../../../../config/config";

interface IState {
  mapIsOpen?: boolean;
  limit: number;
  offset: number;
  total: number;
  filters?: {
    start: number,
    end: number,
  };
  schedules: [];
  event?: IEvent;
  recommendations?: any;
}

export const loadEvent = createAsyncThunk<any, any>('eventSlice/loadEvent',
  async (params: any) => {
    const newParams = {
      ...params,
      schedulesActiveDays: getConfig().eventSchedulesActiveDays,
    };
    const response = await axios.get(api.events.getOne(params._id), { params: newParams });
    return {
      event: response.data.event,
    };
  }
);

export const eventSlice = createSlice<IState, any>({
  name: 'eventSlice',
  initialState: {
    limit: 50,
    offset: 0,
    total: 0,
    schedules: [],
  },
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadEvent.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});
