import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: { count: 0, isLoading: false },
  reducers: {},
  extraReducers: (builder) => builder
    .addMatcher(action => action.type.endsWith('/pending'), state => {
      state.count++;
      state.isLoading = state.count > 0;
    })
    .addMatcher(action => action.type.endsWith('/rejected'), state => {
      state.count--;
      state.isLoading = state.count > 0;
    })
    .addMatcher(action => action.type.endsWith('/fulfilled'), state => {
      state.count--;
      state.isLoading = state.count > 0;
    })
});
