import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactElement, ReactNode } from 'react';
import { uniqueId } from 'lodash';
import { SliceAction } from "../types/sliceAction";

export interface INotification {
  id?: string;
  content: ReactNode | ReactElement | ReactElement[];
  type: 'default' | 'warning' | 'error' | 'success';
}

interface IState {
  notifications: INotification[];
}

interface IRemove {
  payload: { id: string; };
}

export const notificationSlice = createSlice<IState, any>({
  name: 'notifications',
  initialState: {
    notifications: [],
  },
  reducers: {
    push(state, { payload }: PayloadAction<INotification>) {
      state.notifications = [...state.notifications, { ...payload, id: uniqueId('notification_') }];
      // Если уведомлений накопилось больше 5 - удаляем лишние
      if (state?.notifications?.length > 5) {
        const length = state?.notifications?.length;
        state.notifications = state.notifications.slice(length > 5 ? length - 5 : 0);
      }
    },
    remove(state, { payload }: IRemove) {
      state.notifications = state.notifications.filter((n) => n.id !== payload.id);
    },
    clear(state) {
      state.notifications = [];
    }
  },
});

export const notificationPush = notificationSlice.actions.push as SliceAction<INotification>;
export const notificationRemove = notificationSlice.actions.remove as SliceAction;
export const notificationClear = notificationSlice.actions.clear as SliceAction;
