import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { SliceAction } from "../types/sliceAction";
import { getTokenFromLocalStorage, IState as IStateAuth } from "./auth.slice";
import { IInvoice } from "../types/invoices";

interface IState {
  items: IInvoice[];
  total: number;
  offset: number;
}

export const loadInvoices = createAsyncThunk<any, any>('invoices/loadInvoices',
  async (params: any, { getState }) => {
    const state = getState()['invoices'] as IState;
    const stateAuth = getState()['auth'] as IStateAuth;
    const authToken = stateAuth.token || getTokenFromLocalStorage();

    if (!authToken) {
      return null;
    }

    const options = {
      limit: 10,
      offset: state.offset,
      withCancelled: true,
      status: 'paid,refundAwaiting',
    }

    const response = await axios.get(
      api.invoices.get(),
      { params: options, headers: { Authorization: `Bearer ${authToken}` } }
    );

    if (!response?.data.invoices?.length) {
      return null;
    }

    return {
      items: response?.data.invoices,
      total: response?.data?.total,
    };
  }
);

export const invoicesSlice = createSlice<IState, any>({
  name: 'invoices',
  initialState: {
    items: [],
    total: 0,
    offset: 0,
  },
  reducers: {
    setOffset(state: IState, { payload }: PayloadAction<number>) {
      state.offset = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(loadInvoices.fulfilled, (state, { payload }) => {
      if (payload) {
        if (state.offset === 0) {
          state.items = payload.items as IInvoice[];
          state.total = payload.total;
        } else {
          state.items = [...state.items, ...payload.items] as IInvoice[];
          state.total = payload.total;
        }
      }
    });
  }
});

export const setInvoicesOffset = invoicesSlice.actions.setOffset as SliceAction;
