import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from "../../urls";
import { IWeatherData } from '../types/widgetWeather';

export interface IWidgetWeatherState {
  data: {
    mountains?: IWeatherData;
    sea?: IWeatherData;
  };
}

export const loadWeatherThunk = createAsyncThunk<any, any>(
  'widgetWeather/loadWeatherThunk',
  async () => {
    const response = await axios.get(api.weather.get());

    return response.data || {};
  });


export const widgetWeatherSlice = createSlice<IWidgetWeatherState, any>({
  name: 'widgetWeather',
  initialState: {
    data: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadWeatherThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.data = payload;
      }
    });
  }
});
