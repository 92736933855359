import { isEmpty as _isEmpty } from 'lodash';
import { IImage, IImageCrop } from "../redux/types/images";

const fileNameRegExp = /(.*?)(\..*)?$/;
/*
  With only `file` just return original image.
  If `isFullView` is true then return resized image with original ratio
  else return cropped image with specified width and height.
*/
export const getImageUrl = (file: IImage, width: number = null, height: number = null, isFullView = false): string => {
  const imageWidth = width || file?.size?.width;
  const imageHeight = height || file?.size?.height;

  if (!file || _isEmpty(file)) {
    return '';
  }

  let fileName = file.name;

  fileName = fileName.replace('/uploads/', '');

  if (!width || !height) {
    return `/uploads/${fileName}`;
  }

  const [, name, extension] = fileNameRegExp.exec(fileName);

  // TEMP FOR LOCAL DEVELOPMENT
  let url = `/uploads/${isFullView ? 'full/' : ''}${name}_w${imageWidth}_h${imageHeight}`;

  const { crop } = file;

  if (crop) {
    url += `_cx${crop.x}_cy${crop.y}_cw${crop.width}_ch${crop.height}`;
  }

  return url + (extension || '');
};

export const getImageCropFromTop = (image: IImage, aspectRatio: number): IImageCrop => {
  const { size: { width, height } = {} } = image;
  if (!width || !height || !aspectRatio) return null;

  const cropWidth = width;
  const cropHeight = Math.round(cropWidth / aspectRatio);

  if (cropHeight > height) return null;

  return { x: 0, y: 0, width: cropWidth, height: cropHeight };
}
