import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from "../../urls";
import { IStream } from "../types/invoices";

interface IState {
  stream?: IStream;
}

export const loadStreamThunk = createAsyncThunk<any, any>('streamsList/loadStreamThunk',
  async (params: any) => {
    const response = await axios.get(api.streams.getOne(params.id));
    return {
      stream: response?.data?.stream || null,
    };
  }
);

export const streamSlice = createSlice<IState, any>({
  name: 'streamsList',
  initialState: {
    stream: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadStreamThunk.fulfilled, (state, { payload }) => Object.assign(state, payload));
  }
});
