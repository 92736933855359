import { setCookie } from "cookies-next";
import { ParsedUrlQuery } from "querystring";
import { FILTERS_BY_ENTITY_TYPE, IFilters, IMeta } from "../redux/slices/entities.slice";
import { LOCALES_COOKIE_NAME } from "../utils/locales";
import { EntityType } from "../redux/types/common";
import { isStartDateInterim } from "./queryFilter";
import { getUserFriendlyURL } from "./getUserFriendlyURL";
import { createQueryFromFilters } from "./createQueryFromFilters";
import { pick } from "lodash";

const isUserFriendlyURL = (
  { locales = [], places = [], start = null, end = null, allTags = [], genres = [], ...restFilters }: IFilters
) => {

  // Есть хотя бы один фильтр, не относящийся к ЧПУ
  for (const value of Object.values(restFilters)) {
    if (value) {
      return false;
    }
  }

  const interim = isStartDateInterim(start, end);
  // Есть интервал дат
  if (!interim && start && end) {
    return false;
  }

  // Условия использования ЧПУ
  let count = 0;

  if (locales?.length > 1) return false;
  if (locales?.length === 1) count++;

  if (places?.length > 1) return false;
  if (places?.length === 1) count++;

  if (allTags?.length > 1) return false;
  if (allTags?.length === 1) count++;

  if (genres?.length > 1) return false;
  if (genres?.length === 1) count++;

  if (interim) count++;
  if (!interim && start && !end) count++;

  return count <= 2;
}

export function generateQuery(
  filters: IFilters, entityType: EntityType, meta: IMeta, state: any
): { pathname: string, query: ParsedUrlQuery } {
  const filterNames = FILTERS_BY_ENTITY_TYPE[entityType]

  // Сохраняем значение фильтра районов в соответствии с ТЗ:
  // https://fab-i-t.atlassian.net/l/c/HVjQsK2N
  // п.1.1 Фильтр: район проведения
  if (typeof window !== 'undefined') {
    setCookie(LOCALES_COOKIE_NAME, filters.locales.join(','))
  }

  let pathname = '';
  let query: ParsedUrlQuery = {};

  const userFriendlyURL = isUserFriendlyURL(pick(filters, filterNames));
  if (userFriendlyURL) {
    pathname = getUserFriendlyURL(filters, entityType, meta, state);
  } else {
    pathname = `/${entityType}`;
    query = createQueryFromFilters(filters, filterNames, meta);
  }

  return { pathname, query };
}
